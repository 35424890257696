import * as Sentry from '@sentry/node';
import { logEvent } from '../analytics';
import { videoBaseUrl } from '../config';
import { RETRY_PLAY_VIDEO } from './constants';

const RECOVERING_HLS = 'RECOVERING_HLS';
const SET_AUDIO_OUTPUT_FAILED = 'SET_AUDIO_OUTPUT_FAILED';
const DID_SET_AUDIO_OUTPUT = 'DID_SET_AUDIO_OUTPUT';

const logRetry = (videoUrl, error, data, currentRetry) => {
  logEvent(RETRY_PLAY_VIDEO, RETRY_PLAY_VIDEO, {
    videoUrl,
    retryCount: currentRetry,
    error,
    data
  });
};

const handleHlsError = ({
  data,
  hlsInstance,
  hlsGlobal,
  retryCallback,
  videoUrl
}) => {
  setTimeout(() => {
    retryCallback();
  }, 100);

  switch (data.type) {
    case hlsGlobal.ErrorTypes.NETWORK_ERROR:
      if (hlsInstance.startLoad) {
        logEvent(RECOVERING_HLS, RECOVERING_HLS, {
          videoUrl,
          error: data.type
        });
        hlsInstance.startLoad();
      }
      break;
    case hlsGlobal.ErrorTypes.MEDIA_ERROR:
      if (hlsInstance.recoverMediaError) {
        logEvent(RECOVERING_HLS, RECOVERING_HLS, {
          videoUrl,
          error: data.type
        });
        hlsInstance.recoverMediaError();
      }
      break;
    default:
      hlsInstance.destroy();
      break;
  }
};

export const handleRetryPlayVideo = ({
  error,
  data,
  hlsInstance,
  hlsGlobal,
  videoUrl,
  retryCount,
  onRetry
}) => {
  const retryCallback = () => {
    logRetry(videoUrl, error, data, retryCount);
    onRetry?.();
  };
  if (hlsGlobal && hlsInstance && error == 'hlsError' && data?.fatal) {
    handleHlsError({
      data,
      hlsInstance,
      hlsGlobal,
      retryCallback,
      videoUrl
    });
  } else {
    retryCallback();
  }
};

export function setAudioOutputDevice(video, deviceId) {
  let message = '';

  if (!video) {
    message = 'Video element is required';
    logEvent(SET_AUDIO_OUTPUT_FAILED, SET_AUDIO_OUTPUT_FAILED, {
      message
    });
    console.error(message);
    return;
  }
  if (!deviceId) {
    message = 'Device ID is required';
    logEvent(SET_AUDIO_OUTPUT_FAILED, SET_AUDIO_OUTPUT_FAILED, {
      message
    });
    console.error(message);
    return;
  }

  if (typeof video.setSinkId === 'function') {
    video
      .setSinkId(deviceId)
      .then(() => {
        logEvent(DID_SET_AUDIO_OUTPUT, DID_SET_AUDIO_OUTPUT, {
          message: 'Audio output set to device: ' + deviceId
        });
      })
      .catch((error) => {
        Sentry.captureException(error);
        message = 'Audio output set to device failed: ' + deviceId;
        logEvent(SET_AUDIO_OUTPUT_FAILED, SET_AUDIO_OUTPUT_FAILED, {
          message,
          error
        });
        console.error(message);
      });
  }
  // safari fallback
  else if (
    video.webkitAudioOutputDevices &&
    typeof video.setAudioOutputDevice === 'function'
  ) {
    const audioOutputDevices = video.webkitAudioOutputDevices;
    const audioOutputDevice = audioOutputDevices.find(
      (d) => d.deviceId === deviceId
    );
    if (!audioOutputDevice) {
      message = 'Device with ID: ' + deviceId + ' not found';
      logEvent(SET_AUDIO_OUTPUT_FAILED, SET_AUDIO_OUTPUT_FAILED, {
        message
      });
      console.error(message);
      return;
    }
    video.setAudioOutputDevice(audioOutputDevice);
    logEvent(DID_SET_AUDIO_OUTPUT, DID_SET_AUDIO_OUTPUT, {
      message: 'Audio output set to device: ' + deviceId
    });
  } else {
    message =
      'setSinkId method or webkitAudioOutputDevices property not supported in this browser';
    logEvent(SET_AUDIO_OUTPUT_FAILED, SET_AUDIO_OUTPUT_FAILED, {
      message
    });
  }
}

export const generateVideoUrl = (videoUrl: string) =>
  videoUrl.startsWith('http') ? videoUrl : `${videoBaseUrl}${videoUrl}`;
