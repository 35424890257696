import { getCurrentHost } from './window';

export const isInspifyWebApp = (url?: string) => {
  if (!url) return false;
  const inspifyWebAppDomains = [
    'web.inspify.io',
    '.v-boutique.com',
    '.inspify.com'
  ];
  for (const domain of inspifyWebAppDomains) {
    if (url.includes(domain)) {
      return true;
    }
  }
  return false;
};

export const isFromStorybook = (url?: string) => {
  if (!url) return false;
  return url.includes('storybook=');
};

export const appendParamToUrl = (url: string, params: string[]) => {
  const urlObj = new URL(url);
  params.forEach((param) => {
    urlObj.searchParams.append(param, 'true');
  });
  return urlObj.toString();
};

function getHostAndProtocol(urlString) {
  const url = new URL(urlString);
  return {
    host: url.host,
    protocol: url.protocol
  };
}

export const useInspifyWebAppUrlIfNeeded = (url?: string) => {
  const isWebApp = isInspifyWebApp(url);

  if (!isWebApp) return url;

  const { host, protocol } = getHostAndProtocol(url);
  return url.replace(`${protocol}//${host}`, getCurrentHost());
};
