import { delay } from 'lodash';
import React from 'react';
const CSRComponentWrapper = ({
  children,
  onRenderFailed,
  renderDelay
}: {
  children: React.ReactNode;
  onRenderFailed?: () => void;
  renderDelay?: number;
}) => {
  const [isMounted, setIsMounted] = React.useState(false);
  React.useEffect(() => {
    const mount = () => {
      if (typeof window !== undefined) {
        setIsMounted(true);
      } else {
        onRenderFailed?.();
      }
    };
    if (renderDelay) {
      delay(mount, renderDelay);
    } else {
      mount();
    }
  }, []);
  if (!isMounted) return null;
  return <>{children}</>;
};

export default CSRComponentWrapper;
