import React from 'react';
import ReactPlayer from 'react-player';
import { logEvent } from '../../../../analytics';
import CSRComponentWrapper from '../../../../components/CSRComponentWrapper';
import {
  DID_CLICK_PLAY_VIDEO,
  DID_CLICK_UNMUTE_VIDEO,
  DID_START_VIDEO,
  VIDEO_ENDED,
  VIDEO_ERROR
} from '../../../../utils/constants';
import { handleRetryPlayVideo } from '../../../../utils/video';
import { VscMute, VscUnmute } from 'react-icons/vsc';

const VideoPlayerInline = ({
  url,
  playing,
  hideVideo = false,
  controls = true,
  canPlay = true,
  controlsButton = true,
  onDuration,
  loop,
  seekTo,
  onError,
  autoMute,
  audio = false,
  onEnded
}: {
  url: string;
  playing?: boolean;
  hideVideo?: boolean;
  audio?: boolean;
  controls?: boolean;
  controlsButton?: boolean;
  onDuration?: (duration: number) => void;
  loop?: boolean;
  seekTo?: number;
  canPlay?: boolean;
  onError?: (details) => void;
  largePlayButton?: boolean;
  smallPlayButton?: boolean;
  autoMute?: boolean;
  onEnded?: () => void;
}) => {
  const hiresUrl = url.replace(`.m3u8`, `_high.m3u8`);
  const videoUrl = loop ? hiresUrl : url;
  const playerRef = React.useRef<ReactPlayer>();
  const [muted, setMuted] = React.useState(!audio || autoMute);
  const [canControl, setCanControl] = React.useState(controls);
  const [videoReady, setVideoReady] = React.useState(false);
  const [isPlaying, setIsPlaying] = React.useState(canPlay);
  const [retryCount, setRetryCount] = React.useState(0);
  const [mobileBtnUnmute, setMobileBtnUnmute] = React.useState(false);
  const unmuteRef = React.useRef<HTMLDivElement>(null);
  const [videoEnded, setVideoEnded] = React.useState(false);

  const handleError = (e, data, hlsInstance, hlsGlobal) => {
    if (e.name === 'NotAllowedError') {
      if (!muted) {
        setMuted(true);
        setMobileBtnUnmute(true);
      } else {
        setCanControl(true);
      }
    }
    setIsPlaying(false);
    if (e !== 'hlsError' && muted) {
      setMuted(true);
    }
    if (retryCount < 10) {
      const currentRetry = retryCount + 1;
      setTimeout(() => {
        setRetryCount(currentRetry);
        handleRetryPlayVideo({
          error: e,
          data,
          hlsInstance,
          hlsGlobal,
          videoUrl: url,
          retryCount: currentRetry,
          onRetry: () => {
            setIsPlaying(true);
          }
        });
      }, 100);
    } else {
      const logPayload = {
        error: e,
        details: data,
        videoUrl,
        retryCount
      };
      onError?.(logPayload);
      console.log('Error playing video', e);

      if (!onError) {
        logEvent(VIDEO_ERROR, VIDEO_ERROR, logPayload, true);
      }
    }
  };

  React.useEffect(() => {
    if (seekTo !== null && seekTo !== undefined && videoReady) {
      playerRef?.current?.seekTo(seekTo, 'seconds');
    }
  }, [seekTo, videoReady]);

  React.useEffect(() => {
    setTimeout(() => setIsPlaying(!videoEnded && playing && canPlay), 200);
  }, [playing, canPlay, videoEnded]);

  const playButton = (
    <button
      className={`btn btn-play-video btn-outline-light btn-lg smallPlay`}
      onClick={() => {
        setIsPlaying(true);
        logEvent(DID_CLICK_PLAY_VIDEO, DID_CLICK_PLAY_VIDEO);
      }}
    >
      PLAY
    </button>
  );

  return (
    <div className="video-container" id="video-container">
      <CSRComponentWrapper>
        {!hideVideo && (
          <ReactPlayer
            playing={isPlaying && videoReady}
            width="100%"
            height="100%"
            controls={canControl}
            url={videoUrl}
            onStart={() => {
              setVideoEnded(false);
              logEvent(DID_START_VIDEO, DID_START_VIDEO, {
                videoUrl
              });
              if (mobileBtnUnmute) {
                setTimeout(function () {
                  unmuteRef?.current?.classList.add('hidden');
                }, 3000);
              }
            }}
            playsinline
            onPlay={() => {
              setIsPlaying(true);
              setVideoEnded(false);
            }}
            onPause={() => {
              setIsPlaying(false);
            }}
            onDuration={(duration) => {
              onDuration?.(duration);
              setVideoReady(true);
            }}
            muted={muted}
            onEnded={() => {
              logEvent(VIDEO_ENDED, VIDEO_ENDED, {
                videoUrl
              });
              setVideoEnded(true);
              onEnded?.();
            }}
            loop={loop}
            onError={handleError}
          />
        )}
      </CSRComponentWrapper>
      {!controls && controlsButton && (
        <>
          <div className="control-button">
            {!isPlaying && canPlay && playButton}
          </div>
        </>
      )}
      {mobileBtnUnmute && (
        <div
          className="unmute-container"
          onClick={() => {
            setMuted(!muted);
            logEvent(DID_CLICK_UNMUTE_VIDEO, DID_CLICK_UNMUTE_VIDEO);
          }}
        >
          <div className="unmute-icon">
            {muted ? <VscMute /> : <VscUnmute />}
          </div>
          <div ref={unmuteRef} className="unmute-text">
            <div className="text">Tap to unmute</div>
          </div>
        </div>
      )}

      <style jsx>{`
        .video-container {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
        }
        .video-container :global(.btn) {
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
          text-shadow: 0 0 5px rgba(0, 0, 0, 1);
        }
        .control-button {
          position: absolute;
          right: 40px;
          bottom: 16px;
        }
        .video-container :global(.largePlay) {
          color: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border: 1px solid #fff;
          padding: 0;
          aspect-ratio: 1/1;
          width: 30%;
          max-width: 200px;
          border-radius: 100%;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
          text-shadow: 0 0 5px rgba(0, 0, 0, 1);
          background: rgba(255, 255, 255, 0.3);
          opacity: 0.7;
        }
        .video-container :global(.largePlay:hover) {
          opacity: 1;
        }
        .video-container :global(.largePlay svg) {
          width: 50%;
          height: auto;
          margin-left: 10%;
        }

        .video-container :global(.smallPlay) {
          max-width: 70px;
          min-width: 35px;
          font-size: 10px;
          height: auto;
        }

        .unmute-container {
          position: absolute;
          background: black;
          bottom: 10px;
          left: 10px;
          display: flex;
          color: white;
          border-radius: 4px;
          padding: 5px;
          box-shadow: 0 0 5px #f8f9fa66;
          cursor: pointer;
        }
        .unmute-container :global(svg) {
          margin: 5px 7px;
        }
        .unmute-text {
          display: flex;
          align-items: center;
          font-weight: 500;
          margin-right: 10px;
        }

        .hidden {
          animation: closeLeftToRight 0.5s forwards;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        :global(.btn-play-video) {
          opacity: 0;
          animation-name: appear;
          animation-duration: 2s;
          animation-fill-mode: forwards;
        }

        @keyframes appear {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        @keyframes closeLeftToRight {
          0% {
            width: 120px;
          }
          100% {
            width: 0px;
            margin-right: 0px;
          }
        }
      `}</style>
    </div>
  );
};

export default VideoPlayerInline;
