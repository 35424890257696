import { useSspState } from '../../../../components/hooks/common';
import ImageWithWebp from '../../Common/ImageWithWebp';

export const SBLayerImage = ({
  url,
  onError,
  portrait
}: {
  url: string;
  onError: (event: any) => void;
  portrait: boolean;
}) => {
  const isSsp = useSspState();
  const loadingMethod = !isSsp ? 'lazy' : 'eager';
  return (
    <div className="image-layer">
      <ImageWithWebp
        src={url}
        style={{ objectPosition: portrait ? 'center top' : 'center center' }}
        onError={onError}
        loading={loadingMethod}
        className='webp-image-layer' 
      />
      <style jsx>{`
        .image-layer {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          pointer-events: none;
        }
        :global(.webp-image-layer){
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        @media (orientation: portrait) {
          :global(.webp-image-layer){
            object-position: center;
          }
        }
      `}</style>
    </div>
  );
};

export default SBLayerImage;
