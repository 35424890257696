import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { IMainState, LoadingStatus } from '../../../../interfaces';
import { POPUP_DID_LOAD_PAGE, VIEW_PAGE } from '../../../../redux/actions';
import { vbHandleEventFromPopup } from './vbMessageReceiver';
import { sendMessageToPopup } from './vbMessageSender';

const VBToPopupBridge = (props: {
  children: React.ReactNode;
  iframeRef: React.MutableRefObject<any>;
}) => {
  const meeting = useSelector((state: IMainState) => state.clientState.meeting);
  const remotePopupAction = meeting?.remotePopupAction;
  const dispatch = useDispatch();
  const [actionsQueue, setActionsQueue] = React.useState<AnyAction[]>([]);
  const [pageLoadStatus, setPageLoadStatus] = React.useState(
    LoadingStatus.INITIAL
  );

  const handleMessageEvent = (event: MessageEvent) => {
    if (event.data.payload?.type === VIEW_PAGE) {
      //TO-DO: investigate why there are some milliseconds render delay in iframe when VIEW_PAGE received
      setTimeout(() => {
        setPageLoadStatus(LoadingStatus.LOADED);
      }, 1000);
    }
    vbHandleEventFromPopup(event, meeting, dispatch);
  };

  React.useEffect(() => {
    window.addEventListener('message', handleMessageEvent);
    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
  }, []);

  React.useEffect(() => {
    if (remotePopupAction) {
      if (remotePopupAction.type === POPUP_DID_LOAD_PAGE) {
        setPageLoadStatus(LoadingStatus.INITIAL);
        sendMessageToPopup(props.iframeRef, remotePopupAction);
        setActionsQueue([]);
      } else {
        const filteredQueue = actionsQueue.filter(
          (queue) => queue.type !== remotePopupAction.type
        );
        setActionsQueue([...filteredQueue, remotePopupAction]);
      }
    }
  }, [remotePopupAction]);

  React.useEffect(() => {
    if (pageLoadStatus === LoadingStatus.LOADED && actionsQueue?.length) {
      actionsQueue.forEach((action) => {
        sendMessageToPopup(props.iframeRef, action);
      });
      setActionsQueue([]);
    }
  }, [pageLoadStatus, actionsQueue?.length]);

  return <>{props.children}</>;
};

export default VBToPopupBridge;
