import React, { useState } from 'react';
import Mime from 'mime';

const getWebpSrcFromURL = (url: string) => {
  const arr = url.split('.');
  const extension = arr[arr.length - 1]
  return url.replace(`.${extension}`, '.webp');
};

const initSrc = (url: string) => {
  const extension = Mime.getExtension(Mime.getType(url));
  if (extension === 'png' || extension === 'jpeg' || extension === 'jpg') return getWebpSrcFromURL(url);
  return url
  
}
const ImageWithWebp = ({ src, ...props }) => {
  const [imgSrc, setImgSrc] = useState<string>(initSrc(src));
  const onLoadImageError = () => {
    const extension = Mime.getExtension(Mime.getType(imgSrc));
    if (extension === 'webp') {
      setImgSrc(src)
    }
  }

  return imgSrc && <img src={imgSrc} alt={props.alt || ''} {...props} onError={onLoadImageError}/>;
};

export default ImageWithWebp;
