import React from 'react';
import { logEvent } from '../../../../analytics';
import {
  LayerActionPayload,
  LayerActions,
  SceneLayerSettings
} from '../../../../interfaces';
import { generatePageDimension } from '../../../../mappers/polotno';
import ToolTipWrapper from '../../../../components/Common/ToolTipWrapper';
import { DID_SHARE } from '../../../../utils/constants';
import SimpleShareModal from '../../../../components/Sharing/SimpleShareModal';
import { AppContext } from '../../../../components/AppContext';
import { createPortal } from 'react-dom';
import { getCurrentHost, performNativeShare } from '../../../../utils/window';
const DID_CLICK_ACTION_LAYER = 'DID_CLICK_ACTION_LAYER';

const ActionShareLayer = ({
  style,
  payload,
  onClick,
  pageId
}: {
  style: any;
  payload: LayerActionPayload;
  onClick: () => void;
  pageId?: string;
}) => {
  const [open, setOpen] = React.useState(false);
  const { address } = payload;
  const handleShare = (channel = '') => {
    logEvent(DID_SHARE, channel, {
      channel,
      uuid: pageId
    });
  };
  const randomId = React.useMemo(
    () => Math.random().toString(36).substring(7),
    []
  );

  const onShare = () => {
    performNativeShare(
      {
        url: address
      },
      () => {
        handleShare('native');
      },
      () => {
        setOpen(true);
      }
    );
  };
  const shareModal = React.useMemo(() => {
    const storybookWrapper = document.querySelector('#_storybook');
    const nextWrapper = document.querySelector('#__next');
    const target = storybookWrapper || nextWrapper;
    return createPortal(
      <ToolTipWrapper
        onClose={() => setOpen(false)}
        parentId={randomId}
        width={250}
        distanceFromParent={0}
        xPosition="right"
      >
        <div className="control-menu text-left">
          <SimpleShareModal
            show={true}
            url={address}
            onShare={handleShare}
            position="top"
            type="menu"
            qrUrl={address}
            darkMode={true}
          />
        </div>
      </ToolTipWrapper>,
      target
    );
  }, [address, randomId]);
  return (
    <>
      <div
        className="link-block hide-for-client-meeting"
        style={style}
        id={randomId}
        onClick={() => {
          onShare();
          onClick();
        }}
      ></div>
      {open && shareModal}
    </>
  );
};

export const SBLayerActions = ({
  layers,
  portrait,
  onOpenPopup,
  id,
  pageId
}: {
  layers: SceneLayerSettings[];
  portrait: boolean;
  onOpenPopup: (url: string) => void;
  id: string;
  pageId?: string;
}) => {
  const [pageWidth, pageHeight] = React.useMemo(
    () => generatePageDimension(portrait),
    [portrait]
  );
  const { country } = React.useContext(AppContext);
  const additionalParams = React.useMemo(() => {
    const originalParams = new URLSearchParams(location.search);
    originalParams.set('storybook', id);
    return originalParams.toString();
  }, []);

  const getAddressWithLocation = (payload: LayerActionPayload) => {
    if (!payload.locationBasedEnabled) return payload.address;
    const addresses = payload.locationAdresses;
    const currentLocation = country?.toLowerCase();
    if (!currentLocation || !addresses?.length) return payload.address;
    return (
      addresses.find((addr) => addr.location?.toLowerCase() === currentLocation)
        ?.address || payload.address
    );
  };

  const getContentAddress = (payload: LayerActionPayload) => {
    const { contentType, contentId } = payload;
    const host = getCurrentHost();
    switch (contentType) {
      case 'product':
        return `${host}/products/${contentId}`;
      case 'scene':
        return `${host}/scene/${contentId}`;
      case 'storybook':
        return `${host}/storybook/${contentId}`;
      default:
        return '';
    }
  };
  // old legacy // diffrent payload with scene cta
  const transformToNewPayload = (action: any): LayerActions => {
    const { payload, type } = action || {};
    if (type === 'scene') {
      return {
        type: 'content',
        payload: {
          contentType: 'scene',
          contentId: payload.sceneId,
          contentThumbnail: payload.thumbnail,
          title: payload.title
        }
      };
    }
    return action;
  };
  const getContentByAction = (
    action: LayerActions,
    style: any,
    onClick: (payload: any) => void
  ) => {
    const { payload, type } = transformToNewPayload(action || {});
    if (type === 'content' && payload?.contentId) {
      const contentAddress = getContentAddress(payload);
      return (
        <div
          style={style}
          onClick={() => {
            onOpenPopup(contentAddress + '?' + `${additionalParams}`);
            onClick(payload);
          }}
          className="link-block  hide-for-client-meeting"
        />
      );
    }
    if (!payload.address) return null;
    if (type === 'email') {
      return (
        <a
          style={style}
          target="_blank"
          href={`mailto:${payload.address}?subject=${payload.subject || ''}`}
          className="link-block hide-for-client-meeting"
          onClick={() =>
            onClick({
              type: 'share',
              address: payload.address,
              subject: payload.subject || '',
              title: payload.title
            })
          }
        />
      );
    }
    if (type === 'share') {
      return (
        <ActionShareLayer
          payload={payload}
          style={style}
          onClick={() =>
            onClick({
              type: 'share',
              address: payload.address,
              title: payload.title
            })
          }
          pageId={pageId}
        />
      );
    }
    if (type !== 'link') return null;
    const address = getAddressWithLocation(payload);
    const paramsPrefix = address.includes('?') ? '&' : '?';
    const onOpenLinkEvent = () => {
      onClick({
        type: 'link',
        address: address,
        openInPopup: payload.openInPopup || false,
        title: payload.title
      });
    };
    if (payload.openInPopup) {
      return (
        <div
          style={style}
          onClick={() => {
            onOpenPopup(address + paramsPrefix + `${additionalParams}`);
            onOpenLinkEvent();
          }}
          className="link-block  hide-for-client-meeting"
        />
      );
    }

    if (!payload.openInPopup) {
      return (
        <a
          target="_blank"
          style={style}
          href={address}
          onClick={onOpenLinkEvent}
          className="link-block hide-for-client-meeting"
        />
      );
    }
  };

  return (
    <>
      {layers.map((layer, index) => {
        const { action, width, height, x, y } = layer;
        const relativeX = (x / pageWidth) * 100;
        const relativeY = (y / pageHeight) * 100;
        const relativeWidth = (width / pageWidth) * 100;
        const relativeHeight = (height / pageHeight) * 100;
        const style = {
          left: `${relativeX}%`,
          top: `${relativeY}%`,
          width: `${relativeWidth}%`,
          height: `${relativeHeight}%`,
          position: 'absolute',
          display: 'block',
          outline: 'none',
          cursor: 'pointer'
        };
        const onClick = (payload) => {
          logEvent(DID_CLICK_ACTION_LAYER, DID_CLICK_ACTION_LAYER, {
            ...payload,
            pageId: pageId
          });
        };
        return (
          <React.Fragment key={index}>
            {getContentByAction(action, style, onClick)}
          </React.Fragment>
        );
      })}

      <style jsx>{`
        .link-block {
          position: absolute;
          display: block;
          outline: none;
          cursor: pointer;
        }
        :global(.portrait) .link-layer {
          top: 0;
          transform: translate(-50%, 0);
        }
        @media (orientation: portrait) {
          .link-layer {
            top: 0;
            transform: translate(-50%, 0);
          }
        }
      `}</style>
    </>
  );
};

export default SBLayerActions;
