import React from 'react';
import { SceneLayerSettings } from '../../../../interfaces';
import { generatePageDimension } from '../../../../mappers/polotno';
import VideoPlayerInline from '../Common/VideoPlayerInline';

export const SBLayerVideo = ({
  setting,
  onError,
  canPlay,
  hideVideo,
  portrait,
  controls,
  pageIsVisible
}: {
  setting: SceneLayerSettings;
  onError: (details: any) => void;
  canPlay: boolean;
  hideVideo?: boolean;
  portrait: boolean;
  controls?: boolean;
  pageIsVisible?: boolean;
}) => {
  const { url, autoplay, videoLoop, audio, width, height, x, y, opacity } =
    setting;
  const [pageWidth, pageHeight] = React.useMemo(
    () => generatePageDimension(portrait),
    [url, portrait]
  );

  const [isOldChrome, setIsOldChrome] = React.useState(false);
  const relativeX = (x / pageWidth) * 100;
  const relativeY = (y / pageHeight) * 100;
  const relativeWidth = (width / pageWidth) * 100;
  const relativeHeight = (height / pageHeight) * 100;

  React.useEffect(() => {
    const supported = CSS.supports('aspect-ratio: 1/1');
    setIsOldChrome(!supported);
  });

  return (
    <div
      className="video-wrapper"
      style={{
        left: `${relativeX}%`,
        top: `${relativeY}%`,
        width: `${relativeWidth}%`,
        height: `${relativeHeight}%`,
        opacity: `${opacity || 1}`
      }}
    >
      {pageIsVisible && (
        <VideoPlayerInline
          url={url
            ?.replace(/assets\.inspify\.io/g, 'assets.inspify.com')
            ?.replace(/videos\.inspify\.io/g, 'videos.inspify.com')}
          playing={autoplay}
          loop={videoLoop}
          audio={audio}
          canPlay={canPlay}
          controls={controls}
          onError={onError}
          largePlayButton={false}
          smallPlayButton={true}
          hideVideo={hideVideo}
        />
      )}

      <style jsx>{`
        .video-wrapper {
          position: absolute;
          overflow: ${isOldChrome ? 'hidden' : 'visible'};
        }
        .video-wrapper :global(video) {
          object-fit: cover !important;
        }
        :global(.portrait) .video-layer {
          top: 0;
          transform: translate(-50%, 0);
        }
        @media (orientation: portrait) {
          .video-layer {
            top: 0;
            transform: translate(-50%, 0);
          }
        }
      `}</style>
    </div>
  );
};

export default SBLayerVideo;
