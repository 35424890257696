import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../analytics';
import { chopardHighJewelleryId } from '../../config';
import {
    IMainState,
    JitsiLocalTrack,
    MeetingLayoutMode,
    MeetingRole,
    ParticipantMeetingState,
    VirtualBoutiqueMode
} from '../../interfaces';
import { actionLocalTrackAudioMuteDidChange } from '../../redux/actions';
import { isUserOniOS, isUserOnMobile } from '../../utils/deviceDetector';
import { MEETING_DEBUG } from '../../utils/events/meeting';
import { getVirtualBoutiqueMode } from '../../utils/stores';
import { isOnClientSide } from '../../utils/window';

export const useLocalUserMeetingEntitlement = () => {
  const meeting = useSelector(
    (state: IMainState) => state.clientState.meeting || {}
  );

  const canControlMeeting = [
    MeetingRole.ADVISOR,
    MeetingRole.MC,
    MeetingRole.CO_ADVISOR
  ].includes(meeting.localUser?.role);

  const canSeeNavigation = [
    MeetingRole.ADVISOR,
    MeetingRole.CO_ADVISOR
  ].includes(meeting.localUser?.role);

  const isSourceOfMirrorActions = meeting.isPresenter;

  return [canControlMeeting, isSourceOfMirrorActions, canSeeNavigation];
};

export const useHiResVideoParticipantIds = () => {
  const meeting = useSelector(
    (state: IMainState) => state.clientState.meeting || {}
  );
  const remoteUsers = meeting.remoteUsers || {};
  const activeRemoteUsers = Object.keys(remoteUsers)
    .filter((id) => remoteUsers[id]?.connectionStatus !== 'interrupted')
    .reduce((users, id) => {
      users[id] = remoteUsers[id];
      return users;
    }, {});

  if (meeting.layout?.mode === MeetingLayoutMode.TILE) {
    const ids = Object.keys(activeRemoteUsers || {});
    if (ids.length < 3) return ids;
    else return [];
  }

  if (meeting.layout?.mode === MeetingLayoutMode.ONE_THIRD) {
    return Object.values(meeting.layout?.oneThirdLayout || {});
  }
  if (meeting.layout?.enlargedVideoParticipantId) {
    return [meeting.layout?.enlargedVideoParticipantId];
  }

  const studioParticipantId = Object.keys(activeRemoteUsers).find(
    (id) => activeRemoteUsers[id]?.role === MeetingRole.STUDIO
  );

  if (studioParticipantId) {
    return [studioParticipantId];
  }

  return meeting.layout?.presenterIds || [];
};

export const canInteractWithAnimation = () => {
  const meeting = useSelector((state: IMainState) => state.clientState.meeting);
  if (!meeting?.meetingId) {
    return true;
  }
  return meeting.isPresenter;
};

export const useMeetingLocalAudio = (): {
  audio: JitsiLocalTrack;
  muteAudio: (callback?: () => void, restoreLater?: boolean) => void;
  unmuteAudio: (callback?: () => void, restore?: boolean) => void;
  isMuted: boolean;
  restoreAudio: () => void;
  muteAudioAndRestoreLater: () => void;
  activeAudioOutput?: string;
} => {
  const dispatch = useDispatch();
  const localUsers =
    useSelector((state: IMainState) => state.clientState.meeting?.localUser) ||
    {};
  const localTracks = localUsers?.tracks || [];
  const isMuted = localUsers?.audioMuted;
  const activeAudioOutput = localUsers?.activeDevices?.speaker;
  const audio = localTracks.find((t) => t.getType() === 'audio');

  const muteAudio = (callback?: () => void, restoreLater?: boolean) => {
    audio?.mute()?.then(() => {
      dispatch(actionLocalTrackAudioMuteDidChange(true, restoreLater));
      if (callback) {
        callback();
      }
    });
  };

  const unmuteAudio = (callback?: () => void, toBeRestored?: boolean) => {
    audio?.unmute()?.then(() => {
      dispatch(actionLocalTrackAudioMuteDidChange(false, toBeRestored));
      if (callback) {
        callback();
      }
    });
  };

  const restoreAudio = () => {
    if (localUsers?.lastAudioMuted === false) {
      unmuteAudio(undefined, true);
    }
  };

  const muteAudioAndRestoreLater = () => {
    if (!isMuted) {
      muteAudio(undefined, true);
    }
  };

  return {
    audio,
    muteAudio,
    unmuteAudio,
    isMuted,
    restoreAudio,
    muteAudioAndRestoreLater,
    activeAudioOutput
  };
};

export const useAutoPlayVideoMuteControl = (): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>
] => {
  const [playWithSound, setPlayWithSound] = React.useState(true);
  React.useEffect(() => {
    setPlayWithSound(!isUserOnMobile());
  }, []);
  return [playWithSound, setPlayWithSound];
};

export const isAdvisorReadyForMeeting = () => {
  const meeting = useSelector(
    (state: IMainState) => state.clientState.meeting || {}
  );
  const minTrackCount = 0;
  return (
    meeting?.localUser?.tracks?.length >= minTrackCount &&
    meeting?.state === ParticipantMeetingState.IN_THE_LOUNGE &&
    meeting?.localUser?.role === MeetingRole.ADVISOR
  );
};

export const shouldShowClientMeetingVeil = () => {
  const router = useRouter();
  const vbMode = getVirtualBoutiqueMode(router.query.mode as string);
  const meeting = useSelector((state: IMainState) => state.clientState.meeting);
  const store = useSelector((state: IMainState) => state.clientState.store);
  const [canControlMeeting, isSourceOfMirrorActions] =
    useLocalUserMeetingEntitlement();
  if (vbMode !== VirtualBoutiqueMode.COCKPIT) {
    return false;
  }
  if (store?.id === chopardHighJewelleryId) {
    return (
      !canControlMeeting &&
      [
        ParticipantMeetingState.ENTERED_FROM_LOUNGE,
        ParticipantMeetingState.LEFT_MEETING
      ].includes(meeting?.state)
    );
  }
  return (
    meeting?.state === ParticipantMeetingState.ENTERED_FROM_LOUNGE &&
    !isSourceOfMirrorActions &&
    !(
      meeting?.localUser?.role === MeetingRole.MC &&
      meeting?.layout?.enlargedVideoParticipantId
    )
  );
};

const convertedVideosForIosStutteringWorkaround = [
  '0113d773-5272-4ec1-96b6-9df7e472da7d',
  '01e2aa8a-bd6b-48fc-96d4-ab1e9c8d844d',
  '027ca84e-33dc-4650-b8e6-babd396c14d9',
  '02e9e810-a4f1-413e-8b9b-1fdd96f15c27',
  '03253426-a6e2-4d9c-a7be-9a683a08c232',
  '03ade270-4204-4732-9016-ddb086a2228f',
  '06297485-4b24-4d69-9c35-ab04ecfeed8a',
  '06df09fa-45bc-4ca7-8b54-82de17f8d0df',
  '073cbaba-56e6-4048-a2fb-2d11058cb620',
  '0826fcdd-60a2-4203-ab29-254561058b0d',
  '084dc574-a869-42e5-9e4a-888d64078b35',
  '08f369a7-8bc5-4cbc-9b88-013840fe1ebd',
  '0981bcda-3c09-472d-a081-53f1c2abc341',
  '09b8ea8e-e5cc-4799-9ff6-5c84cc7b9025',
  '0b7d10f3-63dc-42ce-88b8-dba013892dec',
  '0ba85883-f7f6-45ff-840a-9e998e6be325',
  '0c4c7919-c7a0-4d1b-8585-52eda2a57740',
  '0ccb5b0e-e27e-440d-bf4d-a746620d2009',
  '0ce3616c-7892-4921-8a0b-e9f8b7efe5c2',
  '0d732f32-2de8-4092-81cd-9e24169f6e79',
  '0e28bb65-49d3-4132-a340-211fec1b35ed',
  '105a82e2-2503-4146-9163-bb50434f4d29',
  '13197352-2fbd-47e3-8c40-ee9810f2a387',
  '140c202d-ccbc-4543-91df-90e8875c4367',
  '14c30d87-6f7a-4bb7-8adb-e5dab7a6707c',
  '1548b536-8d9e-42da-be57-b7f22dc70a1a',
  '16125998-7bde-404b-af95-510e6b313a6d',
  '167988ba-1d91-496c-8e1c-5ad940abc883',
  '17efb646-1eed-4b7d-9558-d0b2faca7e18',
  '186f131d-cf18-405c-9532-198e990e1978',
  '18717675-0395-47eb-a141-d1557c963290',
  '18c0ae65-1ce6-4a58-8cea-388a71d65221',
  '19010670-f032-4734-8ad2-a3476a34d586',
  '1a2a4c65-085d-4f78-bad5-dc3fa2e94fb3',
  '1e286b31-6bc0-4360-909f-cd9c5254f439',
  '24bf6642-1f93-4be2-a901-cf7de44c992c',
  '25f70630-505b-4b7e-9371-63b68579031b',
  '26343cdf-7de3-4cfd-b0e3-dbc1f2144723',
  '275bc925-9ad4-4ea1-8bad-abc5128d5fe3',
  '27fe17a8-9f81-4b31-bc31-8fdfb1f39857',
  '284063a7-de95-4bf6-870b-c03628815652',
  '298c97dd-087d-4e01-9e31-c31c5373254d',
  '2b26de2e-3fcc-4035-9d8f-211c1e0fc989',
  '2b58a903-ebf5-4cc0-9369-f8d3089fa5d0',
  '2c7d8844-4c53-4d5a-b25b-c440023521e8',
  '2cd2b809-8f4b-43c2-8cbc-1a4f6ecadcec',
  '2d5ba841-6262-48c4-b34d-669b6dcf9d84',
  '2eb57def-cace-46e4-b93d-a91c229d97e8',
  '2f360d8e-1460-4bdb-a419-f56fb04b4ccc',
  '2f8d88bd-0512-48db-8d31-1fe50f4e0b5a',
  '2fdf1d0b-76af-4a22-8a07-ce1f926fad85',
  '3139aae2-a2d9-4271-b46f-f256d8222657',
  '31b5cea5-3f74-4528-9a5a-52bfd4274805',
  '33af18f5-a097-4703-af73-7afef719b3f4',
  '3435c777-7c4d-4624-8701-55d401685996',
  '34380f3b-b698-4fe4-9154-ea01d8f87f4b',
  '34a3a653-076b-4f16-83e6-77648af29e51',
  '350c0294-785d-4199-adb6-9eaa7e3b2a0c',
  '355646d0-f0d0-492a-8848-efba0cbb845b',
  '393da672-0bd0-4881-ad93-75a4935a688e',
  '398ad035-7eb4-4ebf-85fb-7da9074abee2',
  '3a42374b-611e-4603-affb-313c90b5b38c',
  '3aef4171-3580-471d-a652-59e49de7cb9f',
  '3af51437-05bf-4fce-9f77-75fbe1f5492a',
  '3b2a749b-3584-4e2e-b1b3-9f52351eb68b',
  '3b9bd1db-de29-408b-8349-81115ca983a5',
  '3bfef635-e9b4-4049-bcad-3b7422a4125f',
  '3c132a1b-24fa-4201-9210-86adca22f198',
  '3cdda476-df92-4aa3-bdaa-5a367000c552',
  '3d8d1b94-508b-4765-a232-604554fcea4b',
  '3df3f9b8-2d73-45d4-b871-4ef39b5ad67b',
  '3fa90a0f-1f8c-4d27-82df-e0155d7b667a',
  '3fc65919-c663-4ced-b6a7-b2ec7c0720c0',
  '406c3c0f-a0f0-4ab1-8c19-2e08dc681bee',
  '41485ed9-5b08-4cd6-a7bc-6e07db63fc0e',
  '4396b7c8-a1d0-4277-8bf4-84bfc482c9e4',
  '43e59803-4fcb-44c1-a8d4-98d935929489',
  '4441b683-41a1-44aa-bd7a-78a5dacc55f7',
  '446eb545-f0d9-4777-9424-83a511719dac',
  '455a409e-4b12-41a6-b21c-a78ff1fd311a',
  '45976c23-3f3c-452a-9fe9-054d85705a44',
  '4657a788-ae8c-42ad-b5b6-a7420efc5682',
  '46a65801-de87-4eac-acbf-2692d3801bf4',
  '47ea9c7b-fba5-47b3-8213-f0ccd14b0f10',
  '482ed3d0-e459-4d28-8c99-3a48641568f9',
  '48877d95-7d66-43b0-82bc-b1088c074a62',
  '48fbe758-99e2-486b-96bb-6643aeb23e0d',
  '490124c4-5a47-4eff-9b3a-2cf650840541',
  '4934e8a9-f5f7-4535-a4fc-f3196cc5d290',
  '496a081a-01f0-476e-b605-e4c9be2e1814',
  '4a4c559d-ed4b-4cbc-b12b-6fdb3e4aac64',
  '4acd4e86-995b-46cb-bf2e-e4f4e78a18d0',
  '4b490dea-b2ac-4c4a-9b49-ebdf6235d018',
  '4d0b45c2-42da-441b-b961-8d2f9e4cdf68',
  '4d4f4be5-e034-4762-aeb3-a55606ee0dd3',
  '4d9410a8-c946-4cc0-902a-da27faefc12b',
  '4dc72d25-cfce-4729-8e7e-6dbcb330caa0',
  '4e048577-4aa6-43c4-9067-de7a897e0f3d',
  '4efba310-0707-4d07-8fa7-e37958f43b2b',
  '50005f83-d5a3-4717-9342-572864b19e2f',
  '500fdc6c-d456-4a96-b372-44d1ca4b6da0',
  '52baee26-a1db-4437-aada-20fc30b02fd6',
  '52c373a8-2ee3-403c-86e6-36fd47ac02fe',
  '53225144-8eed-4db5-8a5c-6b10e6b43426',
  '53337353-087a-4318-9768-99f71a83f1a0',
  '5442cf3e-6be9-4293-9095-d7312abe8377',
  '5472271e-c1f2-44d2-8ee2-15716d087f8a',
  '55e733ae-8ed4-4523-a6cc-4cf661792bb7',
  '56fd948d-0624-411b-b752-7597f8f4ffeb',
  '58a7c874-3f3e-4871-9ed6-f75efac9b8e1',
  '59c26fc4-cf93-4a1c-ab51-a18708eabe5d',
  '5a8f8412-f76a-4afd-a0b0-c29993733e5d',
  '5af0d843-7eb4-4328-8aa0-1be5709f4b12',
  '5bc43a9d-0b0a-4dfc-9176-796052df6b20',
  '5bc9bc6a-0ed9-4826-ae21-f513ed911269',
  '5c1a27f0-01ae-4a22-aaf6-e7d17121a997',
  '5ceaa19b-04f8-4881-bdbc-0b860665db6a',
  '5dcdcb7e-d393-4cd7-84b5-ecc2da109370',
  '5e2c844d-678b-41b8-a8f7-d87db467d92a',
  '5e46c359-193a-4e5d-a45a-298d9fd4047e',
  '5ec57d74-7bbe-4397-b832-9e4d7351711c',
  '5f1d5c5d-d71f-4e93-9237-5df67301b34b',
  '5f9a91ce-f2d9-4ed8-b240-9ebdcebfed5a',
  '60453d8f-63f4-4e28-9b7e-fe1c69e375f0',
  '604775b1-2f39-4884-b4f2-9f3a58894603',
  '605fb1f3-38bb-4639-9b10-259cb80ab386',
  '62b842fb-4a55-4706-957f-e1f571e0baa0',
  '6349934f-b9c0-4f01-a72f-eca29d1faf8c',
  '63a64a06-73ed-496d-a1f1-b2fb0c191b07',
  '64990b6b-2914-42b7-96df-a9afc27b54d2',
  '656aa47f-d707-4c2a-83d1-cc0cfb8183e8',
  '66726bd7-949d-41b5-ae65-e8c10a59e0c5',
  '672f48ca-75df-45f9-9c1d-583a235d4e54',
  '677995d0-595b-4846-9b3c-ae52f42b8673',
  '67ed01a5-ee29-4a49-b6a0-7f5a0d15bf19',
  '67efead6-a996-49db-9c41-d3da8d4469f5',
  '67f49c3e-4327-4857-8e46-17f1a8fb4c5c',
  '68f1c557-3873-4069-83e7-ec6b0e7a7985',
  '69c172e9-a44c-46d7-b17c-c11cfca1e0d8',
  '6a2ebc28-a57c-4321-b064-7a0cadc60c43',
  '6a6e8990-6c57-4afb-91bd-dd847c43d185',
  '6afb2d45-8f6b-472e-857b-459eba9c0af9',
  '6b060743-7e6b-49d8-8fbe-73708796e18a',
  '6b09acbe-e171-4c1f-b486-1e640ee35952',
  '6b51429b-aafc-4fcc-8416-f80951c9e9cd',
  '6b62422d-8b3a-4ff0-af4c-cbb1958b5dab',
  '6c670353-a3dd-4ca8-bd03-a75aab11789a',
  '6cb8cd62-6b14-4936-826a-c1fd2ecaf7ff',
  '6e268081-72cd-4f92-a232-9342752a405d',
  '70311d1a-6cff-42fa-a47d-aae0d36955ae',
  '704f8445-5153-4421-8b6b-915ea46dca8c',
  '718f7bfc-49d0-48dd-bb8f-e2ec8a63af84',
  '71ef7049-9678-445c-a220-3e2f58b72551',
  '720ec578-f682-4252-877a-0810e6837636',
  '746a7df0-6844-4cb6-9cfc-4404f822d3fa',
  '7482714e-1454-4e43-a590-f688ecbeb37d',
  '755cc23f-dad2-41be-9ff6-71362cd57422',
  '75aea18b-2e84-418a-8740-8dbabdf31d71',
  '767ce185-5de8-4e41-bff7-2a12ea2d8077',
  '76c61b35-4bdf-454b-b477-2098472d497c',
  '778e7023-e184-43b8-9146-6cdee9807cee',
  '7843254b-8b98-44ef-abaf-f20cd374069d',
  '78503b17-e99d-464d-a340-e377f78a8a4a',
  '7926f45b-ea82-4ca7-b7dd-bceda02318c3',
  '7a5e5e62-0467-4da1-af11-6ee7b105cd55',
  '7a88d223-a4c6-4156-8545-f6c650afa6af',
  '7b1fa34c-66ed-49bc-b480-90bb6c1682a3',
  '7b8a316c-f5a4-4198-8286-6bc226676bb1',
  '7c09a924-9c49-4e7a-b6a8-652dfeccf6c1',
  '7cd0d7b0-d716-4126-9052-7277df59cb90',
  '7cd915bc-c390-44c4-8e0a-7e0c8a0d1584',
  '7e0a6661-bcc0-4e01-b427-4ab259b26087',
  '7e5244f9-c69e-467c-81e2-1653578aa545',
  '7f32479f-85fc-4fda-8596-bdff0fc1b019',
  '7f80c2a0-3141-4512-a1e9-ade0796163e3',
  '8011010c-8e39-4442-9f50-5215ba5b68ae',
  '80275622-8862-4586-9f25-1a68af86715f',
  '8195ce4b-6600-43ac-bee8-c823dd063d80',
  '81ace823-2eee-4f3f-b012-220f785cf2be',
  '820efdb4-14e7-43c0-af77-8ba10c0f9651',
  '821591e8-d602-4ec4-937a-016031e360f6',
  '8263269b-f837-4b3b-9e73-c0e4d4999907',
  '83d3b84b-6309-4fab-af7b-a4f5dd101f9b',
  '84099b52-2a85-48c8-9a0c-ad06ec487015',
  '841194d1-5fb2-4ffd-9872-a57930b8bff6',
  '8464cd8e-1fdd-488c-bb75-a36ced86dc44',
  '84beccde-5087-47de-ab13-4bde6ae87980',
  '85dfa5fd-3a49-43f1-9aa1-bd4704bf4808',
  '86378da0-127e-4e49-814d-626c77bee4a8',
  '8648279c-2166-46be-a09d-6e0efebd1512',
  '867890e4-0778-4b35-8a01-d8b42c8106a7',
  '86b00bc2-ae1c-44d2-9d24-7f705f2f7cca',
  '89577349-fcf5-4031-9314-7f42389d8694',
  '8a17d396-c035-4bb6-9f1d-039abb060824',
  '995338c9-7a24-4414-a4a5-d4a432dea5fa',
  '9a0ce02e-0bf2-4e52-984e-0d42b814cca9',
  '9a78dd8f-29aa-4da7-a5b9-1c6dba454c2a',
  '9ac1add0-2909-4a37-9132-9d77f87df08a',
  '9b005136-c55d-49c4-bb79-af2359127bc7',
  '9d2c8830-a008-4ca2-b567-517384de330e',
  '9d5806a0-6acf-492b-b504-91bea0372924',
  '9e496e28-65fd-4425-a6e2-ea9a3482a01c',
  '9f1e4f94-dcea-4b30-a036-e614cde8a97b',
  'a08377a2-4cac-43b7-b36c-0d743efb28ab',
  'a1bc0fdc-1510-4350-add6-330fc7a28b7f',
  'a210a13f-13b6-47f0-8cd6-0440885d5d2f',
  'a3714086-29b2-4dda-9f2e-4854835cee9f',
  'a45b1100-5ee2-472c-aba2-6cb339f86e20',
  'a4e1381a-144a-4b7b-b82e-5a5b37b5e83f',
  'a5638e38-67f1-4293-b8c2-b298a109a653',
  'a64a7803-bb5d-4258-b30c-a266ad2d1345',
  'a6e4d1d6-bcb7-4b6b-94cb-382b6d5b207f',
  'a759cd98-63a1-435f-af64-f909870c1d13',
  'a7b35109-f50f-4575-8604-3299ce45873f',
  'a7f802c7-6bb9-4e4a-be58-0c24e78e91e1',
  'a8d3f3cb-5842-4110-875c-4526a08a703d',
  'a988b0b8-4e70-4534-9dec-dabf5508b799',
  'a992a3da-ca78-44a2-9f07-ac435d41ceb2',
  'a9a02f62-cf9b-47e5-b18a-b8ec542ccdbf',
  'aa3c3d07-80c0-4316-b9ab-21ec1bc4ca94',
  'aa6948d4-1250-472d-91da-a6f8b1cbd08e',
  'ab5b0139-c56c-4857-99cb-0a3dde608780',
  'abb29f5b-2a03-4d5a-9f7d-a21f0ad9e1b0',
  'abe98f81-bdae-41fc-9f4b-4d6ccc01f031',
  'ac71633c-5a13-4134-af54-f834a4bdae9f',
  'ad02945f-00b8-42e7-9ada-de969ffaec38',
  'af2ac897-0f0b-41ae-9f22-6c1826c6c866',
  'af2bbb89-0b89-4fcb-83ee-29d576b7ce60',
  'af3400c9-6184-43b0-a459-40546cb1e4b2',
  'b020dfcc-8754-4e1c-b0fb-75da02d183f2',
  'b086effc-8b49-4286-9e26-fbe382fcec7d',
  'b16d0b29-ad39-4304-8566-126bba271b6c',
  'b24cb4f6-34f1-45f1-b1bb-0a961403e73b',
  'b2b5b9ff-e17e-4a0b-9019-22e65e0676b0',
  'b2f8a4d7-f15f-4b04-9b1b-f59aed911642',
  'b3b12486-f596-40c4-b9b3-77688ed0d522',
  'b4f29b95-985c-4ee0-bef3-5f0771067a1a',
  'b6714464-e702-4e5a-8851-f5e1f219f324',
  'b70dead9-ad4e-4f34-a943-92773b19ba9d',
  'b73e3918-dfed-4f46-918a-62c58a54f08c',
  'b75f7f42-0c11-441c-b807-1fcf3ea7aa95',
  'b974a4c5-f7a0-4719-928a-a7b640895cb8',
  'b9860d72-adf7-4744-8fef-cb19931374a6',
  'b9d85b76-7989-49e9-9c60-2940b9c694d0',
  'b9fccbe1-bc48-4bca-b8e8-7c9a114b7c35',
  'ba1abb77-5333-470e-bb85-093a1eaa3885',
  'ba81c578-91e7-4477-8761-00a47acf0f36',
  'bb6c96ec-21bf-4bca-9b15-f4cbeb4dd46b',
  'bd808b0e-8d4e-4424-abea-6c9b313bd7ed',
  'bdb17e67-b621-486a-a0db-89822e83030e',
  'be00b28a-23c1-4af1-a3d2-58572583a343',
  'be262c6d-96b2-4e1c-9c11-e3ac528876b4',
  'bef12a16-7279-4786-a00f-51b6b4e363ba',
  'c1304076-7a27-4160-8ed2-33f39e8d0586',
  'c1e7b1e6-a8d6-48f7-9681-17f675338423',
  'c20a23ce-9a03-4edd-a8fd-c4fed288fb0f',
  'c2335b70-5626-446c-b472-1bfb27d6e121',
  'c28fed35-1999-4b49-9951-d29177737734',
  'c2e65987-243b-40b9-9306-17ee5c43b57d',
  'c3477fe3-a484-4be0-8bc0-82f4c69a2818',
  'c3a33bf2-fe73-47cc-9927-c90345b21e0c',
  'c4f3143c-511b-4955-9054-dc001ffde6c9',
  'c55cb503-ac9a-473d-9580-329e37f117bf',
  'c6671f3f-8fbb-4fe9-8141-a976fe055c97',
  'c681ca69-9dd1-455e-a16d-7064f604c30a',
  'c74892bb-8cb2-4465-8709-0039bc3d5455',
  'c771076c-ffb4-469f-a8a6-f3a4af4ce00d',
  'c7c82573-e081-4130-a712-0629774245d7',
  'c7da7aa6-dff4-4167-9b92-7001f6f27766',
  'c966268c-e9d1-4c0a-902e-1f82f317dc77',
  'c99ba6c1-934f-464a-ac5f-559780a4f317',
  'cb162c8a-de93-4675-8ebf-c7e8b2401e4a',
  'cb300fe1-7cb5-4c78-9bb9-24e06b7c5a17',
  'cbad1769-6037-4d99-85c3-95b9ab98459f',
  'cc582313-d444-4d9d-9ff5-851a6d20b385',
  'cd8f22a0-fbf5-4a02-af46-991e64252cc3',
  'cf23bb05-6a4f-4408-91a7-6ff11260ee4d',
  'cf81955b-adf9-49eb-825f-aa180c139922',
  'cf93b09a-2687-4f8d-95cf-2833963192bd',
  'd008ba04-eaa7-466f-888d-335338dfe167',
  'd1218f39-4eb8-4167-8855-79046fa26a40',
  'd13f4064-037d-48a5-84e2-774c9aaf59e2',
  'd1b27dfb-8104-4ace-bed0-d17d57481c19',
  'd1c64129-bb09-406d-aeb9-27c997d2748c',
  'd3db4f76-92ae-43b6-96ee-866592ab66fa',
  'd5d1767e-e4a6-440e-accd-b3b992ea2ea1',
  'd64727d8-6350-44cf-b40a-1eadfc70d100',
  'd667d85b-8141-4c42-a902-30b76cd3bc66',
  'd68feb68-e156-4955-8638-47f7e7a64d09',
  'd69eaeb6-2838-4b8e-ba6b-9a50de11773f',
  'd6f3bfce-7660-4188-a3ec-2a95195bdf78',
  'd7e42900-e9c4-426a-86cb-0745a1208d45',
  'd97b096d-2870-452f-becd-753a0d511abf',
  'dbf7c999-0d6c-41f6-b5e8-fb5bb6a8e370',
  'de0977ab-f0d7-41f2-835c-bd53b17b5949',
  'deadfaa2-5a03-42c3-8424-7fe4e87b09b0',
  'decd4082-9c7d-469f-8b49-6016a7d4d981',
  'dee8522d-8765-42fe-a203-d08501faae1a',
  'df21ba1c-d7c9-4fae-81e9-d44ea95db597',
  'df66808c-1cd0-49da-bf2b-38d91cb23efe',
  'e084fe08-6d7b-4336-96a2-902ec569019b',
  'e2091114-46d0-46b1-bd3c-dd5ff8e63c6a',
  'e2358c34-2ba6-48a1-b0c2-feb555d01056',
  'e2d4a7b3-e65e-424c-8be0-b56b6f489c6b',
  'e3ef6ac6-c441-44f6-96d8-a1ad919b67b5',
  'e5df40c4-7204-41de-bc0a-3428911344ba',
  'e60341b4-266c-45d7-9e26-09b58e1a168a',
  'e6c60a20-5026-46a5-9020-3ea5b18a1185',
  'e77515d6-b740-4499-8da5-11d198f06f5f',
  'e855fb6a-ccb5-4efd-ac6f-58fb7d98d0fc',
  'e8f3a218-4b45-4285-b3b0-3a53ff745fc0',
  'e93f3347-39e0-42f7-9963-0d0a34e51c44',
  'e9b643d2-ac23-4e55-b450-33b2038a01e9',
  'ea8cdcad-62de-47f9-ac4c-3e83cb0bef9c',
  'ebf9777f-b5e7-41db-a518-ecbf876e229f',
  'ec5679cf-bd7a-4745-b691-980c0a1f9f48',
  'ec8b0e4b-8365-4172-9678-2c1097aa0584',
  'ee23eb9d-9b2d-4a3c-a733-bfc892f80062',
  'ef4895de-115c-4c06-9f0a-c9677e7a7850',
  'f1aca77c-1eca-4efc-bec2-0531d9c5083c',
  'f21f8330-0e62-4c9d-a232-cc61dc64a772',
  'f386bb8a-dd88-471b-9542-5bb538d2c628',
  'f3d2ab05-b636-4da9-8087-59d833724ebc',
  'f3ff9be2-e91d-4def-8837-801afb8fc74c',
  'f47e2660-07e6-43d0-b584-9b2bdbb036c1',
  'f49e81f6-db56-40cb-802c-517ef3220855',
  'f5ed2014-9834-4017-8dea-10340edeec5f',
  'f66146d7-5295-4bcc-b68b-661f050783cb',
  'f76cafe5-eb80-48ff-866a-1b914fa416c5',
  'f85ab0c4-6d14-4b90-9f77-edf083b2d196',
  'f8800e18-603d-4f7d-91fb-ea914fa4b339',
  'f8ab06f1-5e3d-4072-ac74-e5880cf240ed',
  'f8d7619c-165e-4799-8326-94f2ec915f9c',
  'fa79a5b7-fc6e-471b-b519-64b0e89e8f14',
  'fac2b5f2-a0de-40f7-bc97-8cb7e6902249',
  'fc7efff0-0287-4bf8-83ba-1d26dc40328d',
  'fcc9f249-363f-4ab2-9248-3fce98046de1',
  'fce24761-1384-4dfd-8d88-dc5d45b868da',
  'fd73327f-7ed9-4563-9b30-e09ad7e75644',
  'fdb2c070-38f7-4716-8ec0-81b3d6232b03',
  'fe7869ee-d06e-4c47-b1e9-6db2237058be',
  'ff4b30ea-f4f8-4cb8-9bd6-f6b3f60884a5',
  'latest_rdu_content'
];

export const useUpdateVideoUrlForIOSInMeeting = (url: string) => {
  const resolution = 'ds480';
  const meeting = useSelector(
    (state: IMainState) => state.clientState.meeting || {}
  );
  if (!url) return url;
  const isMp4Available = convertedVideosForIosStutteringWorkaround.some((id) =>
    url.includes(`content/converted/${id}`)
  );
  if (!isMp4Available) {
    return url;
  }
  if (!isOnClientSide()) {
    return url;
  }
  if (!isUserOniOS()) {
    return url;
  }
  if (!meeting.meetingId) {
    return url;
  }
  if (!url.endsWith('m3u8')) {
    return url;
  }
  const components = url.split('/');
  const mp4Name = components[components.length - 2];
  components[components.length - 1] = mp4Name.replace(
    /\.mp4|\.mov/,
    `.${resolution}.mp4`
  );
  const mp4FullUrl = components.join('/');
  return mp4FullUrl.replace('videos.inspify.cn', 'videos.inspify.com');
};

export const useMeetingDebugLogger = () => {
  const userAlias = useSelector(
    (state: IMainState) => state.clientState.user?.alias
  );
  const meeting = useSelector(
    (state: IMainState) => state.clientState.meeting || {}
  );

  return (event: string, payload?: Object) =>
    logEvent(MEETING_DEBUG, event, {
      ...(payload || {}),
      meetingId: meeting?.meetingId,
      participantId: meeting?.localUser?.participantId,
      role: meeting?.localUser?.role,
      displayName: userAlias
    });
};
