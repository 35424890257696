import {
  actionOpenVBPopup,
  actionPopupDidRequestHideMeetingVeil,
  actionUpdateStorybookIndex,
  actionUpdateVBPopupPageType,
  REQUEST_HIDE_MEETING_VEIL,
  UPDATE_STORYBOOK_INDEX,
  VB_POPUP_REDIRECT
} from './../../../../redux/actions';
import { IMeeting, ParticipantMeetingState } from '../../../../interfaces';
import {
  REMOTE_POPUP_ACTION,
  VB_POPUP_ACTION,
  OPEN_APPOINTMENT_FROM_POPUP,
  CART_ADD_PRODUCT_FROM_POPUP,
  actionAppointmentPopup,
  actionCartAddProduct,
  OPEN_ENQUIRY_FROM_POPUP,
  actionEnquiryPopup,
  VIDEO_PLAYED_WITH_SOUND,
  actionPopupVideoDidPlayWithSound,
  ADD_PRODUCT_TO_COMPARE,
  actionAddProductToCompare,
  UPDATE_PRODUCT_SEARCH_STATE,
  actionUpdateProductSearchState,
  REMOVE_PRODUCT_FROM_COMPARE,
  actionRemoveProductFromCompare,
  FOCUS_ITEM,
  VIEW_PAGE
} from '../../../../redux/actions';
import { isEmbeddedInStreamingStudio } from '../../../../utils/streamingstudio';

const presenterVbHandleEventFromPopup = (
  event: MessageEvent,
  dispatch: Function
) => {
  if (event.data.type === REMOTE_POPUP_ACTION) {
    dispatch(event.data);
  }
  switch (event.data.payload?.type) {
    case ADD_PRODUCT_TO_COMPARE:
      dispatch(actionAddProductToCompare(event.data.payload?.payload));
      break;
    case REMOVE_PRODUCT_FROM_COMPARE:
      dispatch(actionRemoveProductFromCompare(event.data.payload?.payload));
      break;
    case UPDATE_PRODUCT_SEARCH_STATE:
      dispatch(actionUpdateProductSearchState(event.data.payload?.payload));
      break;
    case UPDATE_STORYBOOK_INDEX:
      dispatch(actionUpdateStorybookIndex(event.data.payload?.payload));
      break;
    case FOCUS_ITEM:
      dispatch(event.data?.payload);
      break;
    default:
      break;
  }
};

const handleEventFromPopup = (event: MessageEvent, dispatch: Function) => {
  window['resetPopupTimer']?.();
  if (event.data.type === VB_POPUP_ACTION) {
    switch (event.data.payload.type) {
      case OPEN_APPOINTMENT_FROM_POPUP:
        dispatch(actionAppointmentPopup(event.data.payload.payload));
        break;
      case OPEN_ENQUIRY_FROM_POPUP:
        dispatch(actionEnquiryPopup(event.data.payload.payload));
        break;
      case CART_ADD_PRODUCT_FROM_POPUP:
        dispatch(actionCartAddProduct(event.data.payload.payload));
        break;
      case VIDEO_PLAYED_WITH_SOUND:
        dispatch(actionPopupVideoDidPlayWithSound(event.data.payload.payload));
        break;
      case REQUEST_HIDE_MEETING_VEIL:
        dispatch(
          actionPopupDidRequestHideMeetingVeil(event.data.payload.payload)
        );
        break;
      case VIEW_PAGE:
        dispatch(
          actionUpdateVBPopupPageType(event.data.payload.payload.pageView)
        );
        break;
      case VB_POPUP_REDIRECT:
        dispatch(actionOpenVBPopup(event.data.payload.payload));
        break;
      default:
        break;
    }
  }
};

export const vbHandleEventFromPopup = (
  event: MessageEvent,
  meeting: IMeeting | undefined,
  dispatch: Function
) => {
  console.log('vbHandleEventFromPopup - received iframe event:');
  console.log(event.data);

  handleEventFromPopup(event, dispatch);

  if (isEmbeddedInStreamingStudio()) {
    presenterVbHandleEventFromPopup(event, dispatch);
  }

  if (
    meeting?.state !== ParticipantMeetingState.ENTERED_FROM_LOUNGE ||
    event.origin !== `${location.protocol}//${location.host}`
  ) {
    return;
  }

  if (meeting?.isPresenter) {
    presenterVbHandleEventFromPopup(event, dispatch);
  }
};
