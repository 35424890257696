import React from 'react';
import { getElementDimension } from '../../../../utils/window';

const PageLabel = ({
  title,
  subtitle,
  background = 'transparent',
  color = '#fff',
  duration,
  textColor = '#fff'
}: {
  title?: string;
  subtitle?: string;
  background?: string;
  color?: string;
  duration?: number;
  textColor?:string;
}) => {
  const [baseSize, setBaseSize] = React.useState(0);
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (ref.current) {
      setBaseSize(
        getElementDimension(ref.current?.parentElement?.parentElement)?.width
      );
    }
  }, []);
  return (
    <div className={`PageLabel ${duration ? 'fadeOut' : ''}`} ref={ref}>
      <div className="backdrop" />
      {title && <div className="title">{title}</div>}
      {subtitle && <div className="subtitle">{subtitle}</div>}
      <style jsx>{`
        .PageLabel {
          color: ${color};
          padding: max(${baseSize * 0.014}px, 10px);
          padding-right: max(${baseSize * 0.08}px, 40px);
          font-size: max(${baseSize * 0.014}px, 10px);
          line-height: 1.2;
          position: relative;
          pointer-events: none;
          text-align: left;
          color:${textColor} !important;
        }
        .PageLabel.fadeOut {
          animation: fadeOut 1s ease-in-out ${duration}s both;
        }
        .backdrop {
          background: linear-gradient(
            90deg,
            ${background} 0%,
            rgba(0, 0, 0, 0) 100%
          );
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 0;
          opacity: 0.8;
        }
        .title,
        .subtitle {
          position: relative;
          z-index: 1;
        }
        .title {
          font-weight: bold;
          font-size: 1.3em;
          letter-spacing: 0.05em;
        }
        .subtitle {
          font-weight: 400;
          font-size: 1em;
          letter-spacing: 0.04em;
        }
        @keyframes fadeOut {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default PageLabel;
