import React, { useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import { IMainState, ParticipantMeetingState } from '../interfaces';
import { actionCloseVBPopup } from '../redux/actions';
import { INS_CLOSE_POPUP } from '../utils/constants';
import { isUserOnMobileOnly } from '../utils/deviceDetector';
import { getPageViewFromUrl, PageView } from '../utils/pageView';
import { useLocalUserMeetingEntitlement } from './hooks/meeting';
import VBToPopupBridge from './Meeting/Messaging/PopupBridge/VBToPopupBridge';
import {
  chopardHappySportGenevaHQ,
  chopardHappySportKorea,
  zIndex
} from '../config';
import CHLoadingIcon from './PixelStream/Chopard/CHLoadingIcon';
import { BsX } from 'react-icons/bs';
import { useTranslation } from '../i18n';
import { isFromStorybook } from '../utils/urlChecker';
import { getSspControls } from '../utils/window';

const PopupPage = () => {
  const storeId = useSelector(
    (state: IMainState) => state.clientState?.store?.id
  );
  const [show, setShow] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>();
  const [_, isSourceOfMirrorActions] = useLocalUserMeetingEntitlement();
  const meetingState = useSelector(
    (state: IMainState) => state.clientState.meeting?.state
  );
  const { t } = useTranslation();
  const isInMeeting =
    meetingState === ParticipantMeetingState.ENTERED_FROM_LOUNGE;

  const canClosePopUp =
    !isInMeeting || (isInMeeting && isSourceOfMirrorActions);

  const popupState = useSelector(
    (state: IMainState) => state.clientState.vb?.popup
  );
  const teleport = useSelector(
    (state: IMainState) => state.clientState.teleport
  );
  const dispatch = useDispatch();

  const isMobile = isUserOnMobileOnly();

  const close = () => {
    if (canClosePopUp) {
      setShow(false);
      document.body.classList.remove('modal-open');
      document.body.classList.remove('modal-pixel-layer');
      if (iframeRef.current) {
        //force iframe fire on unload event
        iframeRef.current.src = 'about:blank';
      }
      setTimeout(() => {
        dispatch(actionCloseVBPopup());
        getSspControls()?.closeDocumentViewer?.();
      }, 500);
    }
  };

  const isPopupStorybook = isFromStorybook(popupState?.url);
  const extraClass = `popup-${getPageViewFromUrl(popupState?.url)
    ?.replace('.', '-')
    ?.replace('_', '-')
    ?.toLowerCase()} viewpage-${popupState?.pageType
    ?.replace('.', '-')
    ?.replace('_', '-')
    ?.toLowerCase()} ${isPopupStorybook ? 'popup-storybook' : ''}`;

  const isProductSearchPage =
    getPageViewFromUrl(popupState?.url) === PageView.PRODUCT_SEARCH;
  const isWishlistPage =
    getPageViewFromUrl(popupState?.url) === PageView.WISHLIST;

  React.useEffect(() => {
    setShow(true);
    document.body.classList.add('modal-open');
    const addedClass = `modal-${
      isProductSearchPage || isWishlistPage || teleport
        ? 'product-page'
        : 'pixel-layer'
    }`;
    const timeoutId = setTimeout(() => {
      document.body.classList.add(addedClass);
    }, 500);

    window[INS_CLOSE_POPUP] = () => {
      close();
    };
    return () => {
      clearTimeout(timeoutId);
      document.body.classList.remove(addedClass);
      document.body.classList.remove('modal-open');
      document.body.classList.remove('modal-check');
    };
  }, [isProductSearchPage]);

  React.useEffect(() => {
    if (show) {
      setPageLoaded(false);
    }
  }, [popupState?.url]);

  React.useEffect(() => {
    const keydownListener = (e) => {
      if (e.key === 'Escape' && show) {
        close();
      }
    };
    window.addEventListener('keydown', keydownListener);
    return () => {
      window.removeEventListener('keydown', keydownListener);
    };
  }, [show]);

  return (
    <VBToPopupBridge iframeRef={iframeRef}>
      <Transition in={show} timeout={500} mountOnEnter unmountOnExit>
        {(state) => (
          <div
            className={`popup-page ${state} ${extraClass}`}
            onClick={() => {
              close();
            }}
            tabIndex={-1}
          ></div>
        )}
      </Transition>
      <Transition in={show} timeout={500} mountOnEnter unmountOnExit>
        {(state) => (
          <div
            className={`popup-page-content ${state} ${
              isMobile ? 'mobile' : ''
            } ${extraClass}`}
            tabIndex={-1}
          >
            <button
              id="popup-close-btn"
              className={`close ${
                popupState?.darkHeader ? 'dark' : ''
              } hide-for-client-meeting`}
              type="button"
              onClick={() => {
                close();
              }}
              tabIndex={-1}
            >
              <BsX size={30} />
            </button>
            {!pageLoaded && (
              <div className="loading">
                {storeId === chopardHappySportKorea ||
                storeId === chopardHappySportGenevaHQ ? (
                  <CHLoadingIcon dark={true} />
                ) : (
                  <Spinner
                    animation="border"
                    variant={isPopupStorybook ? `dark` : 'light'}
                  />
                )}
              </div>
            )}

            <iframe
              id="content-popup"
              src={popupState?.url}
              className={`iframe-container ${pageLoaded ? 'loaded' : ''}`}
              ref={iframeRef}
              onLoad={() => setPageLoaded(true)}
              tabIndex={-1}
              allow="clipboard-write *; web-share *"
            />
          </div>
        )}
      </Transition>
      <style jsx>
        {`
          .loading {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          .popup-page {
            position: fixed;
            z-index: ${zIndex.popupPage};
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.8);
            transition: opacity 0.5s linear;
            overflow: hidden;
          }

          :global(.psExperience) .popup-page {
            display: none;
          }

          .popup-page-content {
            position: fixed;
            margin: auto;
            display: block;
            text-align: center;
            overflow: hidden;
            top: 30px;
            bottom: 30px;
            left: 10vw;
            border-radius: 8px;
            z-index: ${zIndex.popupPage};
            transition: transform 0.3s linear;
          }

          .popup-page-content.popup-storybook {
            background: #fff;
          }

          :global(.psExperience) .popup-page-content {
            top: 0;
            bottom: 0;
            width: 100%;
            border-radius: 0;
            background: #fff;
            transition: all 0.5s linear;
            left: 0;
          }

          .popup-page-content .close {
            position: absolute;
            right: 5px;
            top: 0;
            opacity: 1;
            color: #a0a0a0;
            background-color: transparent;
            padding: 0;
            width: 45px;
            height: 45px;
            font-size: 24px;
            z-index: 1;
          }

          .popup-storybook .close {
            color: #000;
            top: 3px;
            right: 3px;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 100px;
          }
          .popup-storybook .close :global(svg) {
            width: 34px;
            height: auto;
          }

          :global(.psExperience) .viewpage-product-compare .close {
            display: none;
          }

          :global(.psExperience) .popup-page-content .close {
            color: #000;
          }
          :global(.psExperience) .popup-page-content .close::after {
            position: absolute;
            content: '${t('close')}';
            text-transform: uppercase;
            font-size: 0.6em;
            right: 8px;
            text-shadow: none;
            top: 50%;
            font-weight: 400;
            transform: translate(-110%, -50%);
            display: block;
          }

          .popup-page .close.dark {
            color: #fff;
          }

          .popup-page.entering,
          .popup-page.exiting,
          .popup-page.exited {
            opacity: 0;
          }
          .popup-page.entered {
            opacity: 1;
          }
          .popup-page-content.entering,
          .popup-page-content.exiting,
          .popup-page-content.exited {
            transform: translateY(100%);
          }

          .popup-page-content.entered {
            transform: translateY(0%);
          }

          .iframe-container {
            width: 100vw;
            height: 100%;
            border: none;
            background: #fff;
            opacity: 0;
            transition: all 1s linear;
            transition-delay: 0.5s;
          }

          :global(.psExperience) .iframe-container {
            width: 100%;
          }

          .iframe-container.loaded {
            opacity: 1;
          }

          @media (min-width: 769px) {
            .iframe-container {
              width: 80vw;
              height: 100%;
            }

            .popup-page .close {
              right: 10px;
              width: 50px;
              height: 50px;
              color: #a0a0a0;
            }
          }

          @media (min-width: 840px) {
            .popup-page {
              bottom: 0;
              left: 0;
            }

            .popup-page-content {
              left: 10vw;
            }
            .popup-page-content.popup-product,
            .popup-page-content.popup-inspirations,
            .popup-page-content.popup-inspiration-by_tag {
              width: 80vw;
            }
            .popup-page-content.exiting,
            .popup-page-content.exited,
            .popup-page-content.entering {
              opacity: 0;
            }
            .popup-page-content.entered {
              opacity: 1;
            }

            .popup-page-content.popup-storybook.entering,
            .popup-page-content.popup-storybook.exiting,
            .popup-page-content.popup-storybook.entered,
            .popup-page-content.popup-storybook.exited {
              opacity: 1;
            }

            :global(.psExperience) .popup-page-content {
              width: min(1000px, 50vw);
              right: 0;
              left: auto;
              transform: translateX(100%);
            }
            :global(.psExperience)
              .popup-page-content.viewpage-product-compare {
              width: min(80vw, 1600px);
            }
            :global(.psExperience) .popup-page-content.exiting,
            :global(.psExperience) .popup-page-content.exited,
            :global(.psExperience) .popup-page-content.entering {
              opacity: 1;
              transform: translateX(100%);
            }
            :global(.psExperience) .popup-page-content.entered {
              opacity: 1;
              transform: translateX(0%);
            }
            :global(.psExperience) .popup-page-content.exiting,
            :global(.psExperience) .popup-page-content.exited,
            .popup-page-content.popup-side {
              left: 220px;
              width: calc(100vw - 230px);
              top: 10px;
              bottom: 10px;
            }

            .iframe-container {
              width: 80vw;
            }

            .popup-side .iframe-container {
              width: calc(100vw - 230px);
            }
          }

          @media (max-width: 769px) {
            .popup-page-content {
              top: 50px;
              bottom: 0;
              left: auto;
              transform: none;
              border-radius: 8px 8px 0 0;
            }
            .popup-page-content.popup-storybook {
              top: 0;
              border-radius: 0;
            }
            .popup-page-content.popup-storybook .close {
              top: 0;
              right: 0;
            }
            .popup-page-content .close.dark {
              opacity: 1;
              color: #000;
            }

            .popup-page-content .close {
              opacity: 1;
            }
            .popup-page-content.popup-product,
            .popup-page-content.popup-inspirations,
            .popup-page-content.popup-inspiration-by_tag {
              width: 100vw;
            }
          }

          @media (max-height: 800px) and (min-width: 768px) {
            .popup-page-content {
              top: 20px;
              height: auto;
              bottom: 0;
              border-radius: 8px 8px 0 0;
            }
          }

          @media (max-height: 500px) {
            .popup-page-content {
              height: auto;
              top: 0;
              border-radius: 0;
            }
            .popup-page-content .close {
              color: #ccc;
              opacity: 1;
            }
          }
        `}
      </style>
      <style jsx global>{`
        @media (min-width: 840px) {
          .vbssp .popup-page-content {
            left: 30px;
            right: 30px;
          }
          .vbssp .iframe-container {
            width: calc(100vw - 60px);
          }
        }
      `}</style>
    </VBToPopupBridge>
  );
};

export default PopupPage;
