import { AnyAction } from 'redux';
import React from 'react';

export const sendMessageToPopup = (
  iframeRef: React.MutableRefObject<any>,
  remotePopupAction: AnyAction
) => {
  iframeRef.current?.contentWindow?.postMessage(
    remotePopupAction,
    `${location.protocol}//${location.host}`
  );
};
