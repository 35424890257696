import React from 'react';
import { IStorybookPage, IStorybookSettings } from '../../../../interfaces';
import { TLanguage } from '../../../../mappers/polotno';
import { isVideo } from '../../../../utils/file';
import { getElementPosition } from '../../../../utils/window';
import { getStorybookPageType } from '../../../utils/storybook';
import PageLabel from '../Common/PageLabel';
import { SBImagePage, SBLayerPage, SBVideoPage } from './SBMicrositePageType';

const SBMicrositePage = ({
  page,
  hideVideo,
  setting,
  active,
  onFocus,
  parentId,
  handleError,
  portrait,
  forceMute,
  storybookId,
  activateLanguage
}: {
  page: IStorybookPage;
  hideVideo?: boolean;
  setting?: IStorybookSettings;
  active?: boolean;
  onFocus?: () => void;
  parentId?: string;
  handleError?: (message: string, payload: any) => void;
  portrait?: boolean;
  forceMute?: boolean;
  storybookId?: string;
  activateLanguage?: TLanguage;
}) => {
  const { color, duration, background, text, layer } = setting;
  const url = layer?.desktop?.[0]?.url || page.url;
  const ref = React.useRef<HTMLDivElement>(null);

  const isVideoPage = isVideo(url);

  React.useEffect(() => {
    const parentPosition = getElementPosition(
      document.getElementById(parentId)
    );
    const handleParentScroll = () => {
      if (!ref?.current) return;
      const myPosition = getElementPosition(ref.current);
      if (parentPosition.left - myPosition.left === 0) {
        onFocus?.();
      }
    };
    if (parentId) {
      const element = document.getElementById(parentId);
      if (element) {
        element.addEventListener('scroll', handleParentScroll);
      }
    }
    return () => {
      if (parentId) {
        const element = document.getElementById(parentId);
        if (element) {
          element.removeEventListener('scroll', handleParentScroll);
        }
      }
    };
  }, [ref]);

  const pageType = getStorybookPageType(page, setting);

  return (
    <div
      className={`content content-${pageType} ${portrait ? 'portrait' : ''}`}
      ref={ref}
      style={{ background: background || '#000' }}
    >
      {pageType === 'video' && (
        <SBVideoPage
          page={page}
          hideVideo={hideVideo}
          setting={setting}
          canPlay={active}
          handleError={handleError}
          portrait={portrait}
          forceMute={forceMute}
          controls={false}
          controlsButton={false}
        />
      )}
      {pageType === 'image' && (
        <SBImagePage
          page={page}
          setting={setting}
          handleError={handleError}
          portrait={portrait}
        />
      )}

      {pageType === 'layer' && (
        <SBLayerPage
          portrait={portrait}
          page={page}
          setting={setting}
          hideVideo={hideVideo}
          canPlay={active}
          handleError={handleError}
          id={storybookId}
          activateLanguage={activateLanguage}
        />
      )}

      {(page?.title || page?.subtitle) && active && (
        <div className="label-container">
          <PageLabel
            key={page?.title}
            title={page?.title}
            subtitle={page?.subtitle}
            background={color}
            duration={duration}
            textColor={text}
          />
        </div>
      )}

      <style jsx>{`
        .label-container {
          position: absolute;
          pointer-events: none;
          left: 0;
          bottom: 0;
        }
        .content {
          width: 100%;
          height: 100%;
          overflow: hidden;
          position: relative;
          text-align: center;
        }
        .content:hover .label-container {
          display: ${isVideoPage ? 'none' : 'block'};
        }

        .content-video :global(.video-container .control-button) {
          bottom: 60px !important;
        }

        .content-video.portrait :global(video) {
          height: 100% !important;
          object-fit: cover !important;
        }
        @media (orientation: portrait) {
          .content-video :global(video) {
            height: 100% !important;
            object-fit: cover !important;
          }
        }
      `}</style>
    </div>
  );
};

export default SBMicrositePage;
