import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';

export const parseUrlStringToAnchor = (inputText: string) => {
  let replacedText;
  const replacePattern1 =
    /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacedText = inputText?.replace(
    replacePattern1,
    '<a href="$1" target="_blank">$1</a>'
  );

  const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText?.replace(
    replacePattern2,
    '$1<a href="http://$2" target="_blank">$2</a>'
  );

  const replacePattern3 =
    /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText?.replace(
    replacePattern3,
    '<a href="mailto:$1">$1</a>'
  );

  return replacedText;
};

export const parseLineBreakToBr = (inputText: string, isTitle?: boolean) => {
  return isTitle
    ? inputText?.replace(/(?:\r\n|\r|\n|\\n)/g, '<br>')?.replace(':', ':<br>')
    : inputText?.replace(/(?:\r\n|\r|\n|\\n)/g, '<br>');
};

export const parsePlainTextToRichText = (
  inputText: string,
  isTitle?: boolean
): string[] => {
  let replacedText;
  replacedText = parseLineBreakToBr(inputText, isTitle);
  replacedText = parseUrlStringToAnchor(replacedText);
  return ReactHtmlParser(replacedText);
};

export const getTwoLetterFromName = (name: string) => {
  const nameArr = name?.split(' ');
  const firstName = nameArr?.[0];
  const lastName = nameArr?.[1];
  if (firstName && lastName) {
    return (firstName[0] + lastName[0]).toUpperCase();
  }
  if (firstName?.length > 1) {
    return (firstName[0] + firstName[1]).toUpperCase();
  }
  if (firstName?.length === 1) {
    return firstName[0].toUpperCase();
  }
  if (!firstName) {
    return '?';
  }
};

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const checkIfNumber = (key: string) => {
  const regex = /^\d+$/;
  return regex.test(key);
};

export const convertMilisToDuration = (milis: number) => {
  if (!milis) return;
  const roundedSeconds = Math.floor(milis / 1000);
  if (!roundedSeconds) return '00:01';
  const timeFromUnixStr = new Date(milis).toISOString();
  const hours = timeFromUnixStr.substring(11, 13);
  const mmss = timeFromUnixStr.substring(14, 19);
  return `${+hours > 0 ? hours + ':' : ''}${mmss}`;
};

export const convertMilisToApproximateTime = (milis: number) => {
  if (!milis) return;
  const hrs = milis / 1000 / 60 / 60;
  if (Math.floor(hrs)) {
    return `${hrs % 1 === 0 ? hrs : hrs.toFixed(2)}hrs`;
  }
  const min = milis / 1000 / 60;
  if (Math.floor(min)) {
    return `${min % 1 === 0 ? min : min.toFixed(2)}m`;
  }
  return `${Math.ceil(milis / 1000)}s`;
};

export const convertSecondsToDuration = (seconds: number) => {
  const roundedSeconds = Math.floor(seconds);
  const hrs = Math.floor(roundedSeconds / 3600);
  const min = Math.floor((roundedSeconds - hrs * 3600) / 60);
  const sec = roundedSeconds - hrs * 3600 - min * 60;
  let hrsStr = '',
    minStr = `${min}:`,
    secStr = `${sec}`;

  if (hrs > 0) {
    hrsStr = `${hrs}:`;
  }

  if (hrs > 0 && min < 10) {
    minStr = `0${min}:`;
  }

  if (sec < 10) {
    secStr = `0${sec}`;
  }

  return `${hrsStr}${minStr}${secStr}`;
};

export const convertMilisecondsToDuration = (milis: number) => {
  if (!milis) return;
  const roundedSeconds = Math.floor(milis / 1000);
  //round up to 1s if less then 1s
  if (roundedSeconds === 0) return '1s';
  const hrs = Math.floor(roundedSeconds / 3600);
  const min = Math.floor((roundedSeconds - hrs * 3600) / 60);
  const sec = roundedSeconds - hrs * 3600 - min * 60;
  //put zero in front of number if less then 10 and larger unit > 0.
  const hrsStr = hrs > 0 ? `0${hrs}h`.slice(-3) : '';
  const minStr = min > 0 ? `${hrs > 0 ? '0' : ''}${min}m`.slice(-3) : '';
  const secStr = `${hrsStr.length || minStr ? '0' : ''}${sec}s`.slice(-3);
  //remove space
  if (!hrsStr) {
    if (!minStr) return `${secStr}`;
    return `${minStr} ${secStr}`;
  }
  return `${hrsStr} ${minStr} ${secStr}`;
};

export const convertDateToMoment = (date: string) => {
  return moment(new Date(date)).from(new Date());
};

export const getUrlsFromString = (str = '') =>
  str?.match(/\bhttps?::\/\/\S+/gi) || str?.match(/\bhttps?:\/\/\S+/gi);

export const getByteLengthFromString = (str: string) => {
  // source: https://stackoverflow.com/a/23329386
  let s = str.length;
  for (let i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;
    else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xdc00 && code <= 0xdfff) i--;
  }
  return s;
};

export const isStringExceedMaxByteLimit = (str: string, limitInByte: number) =>
  getByteLengthFromString(str) > limitInByte;

export const getDuplicateString = (str: string[]) => {
  const uniqueIds = new Set();
  const duplicates = new Set();
  str.forEach((element) => {
    const isDuplicate = uniqueIds.has(element);
    uniqueIds.add(element);
    if (isDuplicate) duplicates.add(element);
  });
  return duplicates;
};
export const cleanLowerCase = (text?: string) =>
  text?.toLowerCase().trim() || null;

export const removeExtraSpaces = (text?: string) =>
  text.trim().replace(/\s+/g, ' ');