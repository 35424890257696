import React from 'react';
import { useSelector } from 'react-redux';
import { IMainState, ViewportInfo } from '../../interfaces';

export function usePageVisibility() {
  const [isVisible, setIsVisible] = React.useState(true);

  React.useEffect(() => {
    const visibilityChangeListener = () => {
      setIsVisible(document.visibilityState === 'visible');
    };

    document.addEventListener('visibilitychange', visibilityChangeListener);
    return () => {
      document.removeEventListener(
        'visibilitychange',
        visibilityChangeListener
      );
    };
  }, []);

  return isVisible;
}

export const useViewportInfo = (): ViewportInfo => {
  return useSelector(
    (state: IMainState) => state.clientState.viewport || { width: 0, height: 0, onPhone: false, onMobile: false }
  );
};
