import React from 'react';
import { generatePageDimension } from '../../../../mappers/polotno';

export const SBLayerWrapper = ({
  portrait,
  parentDimension,
  children
}: {
  portrait: boolean;
  parentDimension: { width: number; height: number };
  children: React.ReactNode;
}) => {
  const { width: parentWidth, height: parentHeight } = parentDimension;
  const [isOldChrome, setIsOldChrome] = React.useState(false);
  const [wrapperWidth, setWrapperWidth] = React.useState(0);
  React.useEffect(() => {
    const supported = CSS.supports('aspect-ratio: 1/1');
    setIsOldChrome(!supported);
  }, []);

  const [pageWidth, pageHeight] = React.useMemo(
    () => generatePageDimension(portrait),
    [portrait]
  );

  const parentIsTooWide = parentWidth / parentHeight > pageWidth / pageHeight;

  //extra pixel ratio:
  // we want to add 2px extra size to the page
  // so we can crop the edge by 1px to prevent white line appear
  // value is precentage of 2px/pageWidth

  const extraPixelRatioWidth = 0.104 * 4; // 2px/1920

  const innerWrapperHeight = 100 + extraPixelRatioWidth;

  //old chrome doesn't support aspect-ratio
  //which use on android webview (SSP)
  //need to use padding-top hack

  //calculate padding top base on pageWidth/pageHeight
  const padding = Number((pageHeight / pageWidth) * 100).toFixed(2);
  const shouldUsePaddingTop = isOldChrome && !parentIsTooWide;
  const shouldCalculateWidth = isOldChrome && parentIsTooWide;
  React.useEffect(() => {
    if (!shouldCalculateWidth) return;
    const onResize = () => {
      const wrapper = document.querySelector('.wrapper-inner');
      const h = wrapper?.clientHeight;
      const w = (pageWidth / pageHeight) * h;
      setWrapperWidth(w);
    };
    window.addEventListener('resize', onResize);
    onResize();
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [shouldCalculateWidth]);

  return (
    <div
      className="layer-wrapper"
      style={{
        aspectRatio: `${pageWidth} / ${pageHeight}`
      }}
    >
      <div
        className="wrapper-inner"
        style={{
          aspectRatio: `${pageWidth} / ${pageHeight}`
        }}
      >
        {children}
      </div>
      <style jsx>{`
        .wrapper-inner {
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          height: ${parentIsTooWide ? `${innerWrapperHeight}%` : `auto`};
          width: ${parentIsTooWide
            ? isOldChrome
              ? wrapperWidth + 'px'
              : `auto`
            : `${innerWrapperHeight}%`};
          padding-top: ${shouldUsePaddingTop ? `${padding}%` : `unset`};
        }
        .layer-wrapper {
          object-fit: contain;
          position: relative;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          overflow: hidden;
          // background: #FFF;
          padding-top: ${shouldUsePaddingTop ? `${padding}%` : `unset`};
          height: ${parentIsTooWide ? `100%` : `auto`};
          width: ${parentIsTooWide ? `auto` : `100%`};
        }
      `}</style>
    </div>
  );
};

export default SBLayerWrapper;
