import {
  IFont,
  IMainState,
  IProduct,
  IWatchSpecification,
  SceneLayerSettings
} from '../../../../interfaces';
import React from 'react';
import { generatePageDimension } from '../../../../mappers/polotno';
import {
  getProductDisplayPrice,
  getProductSpecificationsByLang
} from '../../../../utils/product';
import { useTranslation } from 'next-i18next';
import Head from 'next/head';
import { useSelector } from 'react-redux';
import { AppContext } from '../../../../components/AppContext';
import ImageWithWebp from '../../Common/ImageWithWebp';
import { fonts as customFonts } from '../../Polotno/customFonts';
const S_BRIGHNESS = 200;

type RGBColor = {
  red: string;
  green: string;
  blue: string;
  alpha: string;
};
export const SBLayerProduct = ({
  onError,
  portrait,
  setting,
  product,
  fonts
}: {
  onError: (event: any) => void;
  portrait: boolean;
  setting: SceneLayerSettings;
  product: IProduct;
  fonts: IFont[];
}) => {
  const { product: settingProduct, width, height, x, y, opacity } = setting;
  const { defaultValue, type, field } = settingProduct;
  const { country } = React.useContext(AppContext);
  const [pageWidth, pageHeight] = React.useMemo(
    () => generatePageDimension(portrait),
    [defaultValue, portrait]
  );
  const relativeX = (x / pageWidth) * 100;
  const relativeY = (y / pageHeight) * 100;
  const relativeWidth = (width / pageWidth) * 100;
  const relativeHeight = (height / pageHeight) * 100;

  const viewportWidth =
    useSelector((state: IMainState) => state.clientState.viewport?.width) ||
    pageWidth;
  const relativeFont = portrait ? pageWidth / 1920 : viewportWidth / 1920;
  function rgbToObj(rgb): {
    red: string;
    green: string;
    blue: string;
    alpha?: string;
  } {
    if (rgb === 'black')
      return {
        red: '0',
        green: '0',
        blue: '0'
      };
    if (rgb === 'white')
      return {
        red: '255',
        green: '255',
        blue: '255'
      };
    const colors = ['red', 'green', 'blue', 'alpha'];

    const colorArr = rgb
      .slice(rgb.indexOf('(') + 1, rgb.indexOf(')'))
      .split(',');

    const obj: RGBColor = {} as any;

    colorArr.forEach((k, i) => {
      obj[colors[i]] = k;
    });

    return obj;
  }
  const withOpacity = (_color: string, opacity: number) => {
    const color = rgbToObj(_color);
    const { red, green, blue } = color;
    return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
  };
  const getBlur = (setting) => {
    if (setting.blurEnabled) {
      return `${setting.blurRadius}px`;
    }
    return '0';
  };
  function getShadow(setting) {
    if (setting.shadowEnabled)
      return `${setting.shadowOffsetX}px ${setting.shadowOffsetY}px ${
        setting.shadowBlur
      }px ${withOpacity(setting.shadowColor, setting.shadowOpacity)}`;
    return '';
  }

  const getBrightness = (setting) => {
    if (setting.brightnessEnabled) {
      return `${S_BRIGHNESS * setting.brightness}%`;
    }
    return '1';
  };

  const getGrayScale = (setting) => {
    if (setting.grayscaleEnabled) {
      return `grayscale(1)`;
    }
    return '';
  };

  const getTextShadow = (setting) => {
    if (setting.shadowEnabled)
      return `${setting.shadowOffsetX}px ${setting.shadowOffsetY}px ${
        setting.shadowBlur
      }px ${withOpacity(setting.shadowColor, setting.shadowOpacity)}`;
    return 'none';
  };
  const { i18n } = useTranslation();
  const getContentByField = (field: string) => {
    const language = i18n.language;
    if (!product) return undefined;
    const specs = getProductSpecificationsByLang(
      product,
      language
    ) as IWatchSpecification;
    switch (field) {
      case 'material': {
        return specs?.material;
      }
      case 'officialSalesPrices': {
        return getProductDisplayPrice(product, country);
      }
      case 'description': {
        return specs?.description;
      }
      default:
        return product[field];
    }
  };
  if (!defaultValue || !type) return null;
  if (type === 'text') {
    const fontUrl = [...fonts, ...customFonts].find(
      (f) =>
        f.fontFamily === setting.fontFamily?.replace('-', ' ') ||
        f.fontFamily === setting.fontFamily
    )?.url;

    return (
      <>
        {!fontUrl && (
          <Head>
            <link
              href={`https://fonts.googleapis.com/css?family=${setting.fontFamily}`}
              rel="stylesheet"
              key={setting.fontFamily}
            />
          </Head>
        )}
        <p
          style={{
            position: 'absolute',
            left: `${relativeX}%`,
            top: `${relativeY}%`,
            width: `${relativeWidth}%`,
            height: `${relativeHeight}%`,
            opacity: `${opacity || 1}`
          }}
          className="text-layer"
          data-field={field}
        >
          {getContentByField(field) || defaultValue}
          <style jsx>{`
            @font-face {
              font-family: ${setting.fontFamily};
              src: url(${fontUrl || ''});
            }
            .text-layer[data-field='officialSalesPrices'] {
              white-space: nowrap;
            }
            .text-layer {
              text-align: ${setting.align};
              vertical-align: ${setting.verticalAlign};
            }
            .text-layer {
              font-size: ${setting.fontSize * 0.72 * relativeFont}px;
              font-family: ${setting.fontFamily};
              vertical-align: ${setting.verticalAlign};
              color: ${setting.fill};
              font-weight: ${setting.fontWeight};
              font-style: ${setting.fontStyle};
              letter-spacing: ${setting.letterSpacing}px;
              -webkit-text-stroke-width: ${setting.strokeWidth / 2}px;
              -webkit-text-stroke-color: ${setting.stroke};
              text-shadow: ${getTextShadow(setting)};
              text-decoration: ${setting.textDecoration};
              transform: rotate(${setting.rotation}deg);
              transform-origin: top left;
            }
          `}</style>
        </p>
      </>
    );
  }
  return (
    <div
      className="image-layer"
      style={{
        left: `${relativeX}%`,
        top: `${relativeY}%`,
        width: `${relativeWidth}%`,
        height: `${relativeHeight}%`,
        opacity: `${opacity || 1}`
      }}
    >
      <ImageWithWebp
        className="product-webp"
        src={defaultValue}
        onError={onError}
      />
      <style jsx>{`
        .image-layer {
          position: absolute;
          pointer-events: none;
          border: ${setting.borderSize}px solid ${setting.borderColor};
          border-radius: ${setting.cornerRadius}px;
          transform: rotate(${setting.rotation}deg);
          transform-origin: top left;
          filter: blur(${getBlur(setting)})
            brightness(${getBrightness(setting)}) ${getGrayScale(setting)};
        }
        :global(.product-webp) {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top center;
          border-radius: ${setting.borderRadius || 0}px;
          filter: drop-shadow(${getShadow(setting)});
        }
        @media (orientation: portrait) {
          :global(.product-webp) {
            object-position: center;
          }
        }
      `}</style>
    </div>
  );
};

export default SBLayerProduct;
