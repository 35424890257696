import React from 'react';
import { MdCheckCircle } from 'react-icons/md';
import { FileType } from '../../../../interfaces';
import { isVideo } from '../../../../utils/file';
import { convertSecondsToDuration } from '../../../../utils/string';
import { IconPlay } from '../../Common/HubIcons';
import ImageWithWebp from '../../Common/ImageWithWebp';
import VideoPlayerInline from './VideoPlayerInline';

const SBThumbnailCover = ({
  url,
  onClick,
  showDuration,
  thumbnailInfo,
  objectFit = 'cover',
  backgroundColor,
  type,
  selected,
  videoCanPlay = true,
  disabled,
  portrait,
  portraitUrl,
  overlay
}: {
  url?: string;
  onClick?: () => void;
  showDuration?: boolean;
  thumbnailInfo?: string;
  objectFit?: 'cover' | 'contain';
  backgroundColor?: string;
  type?: FileType;
  selected?: boolean;
  videoCanPlay?: boolean;
  disabled?: boolean;
  portrait?: boolean;
  portraitUrl?: string;
  overlay?: React.ReactNode;
}) => {
  const [imageLoaded, setImageLoaded] = React.useState<
    'init' | 'loaded' | 'error'
  >('init');

  const [canLoadPortrait, setCanLoadPortrait] = React.useState(true);
  const [duration, setDuration] = React.useState<number>(0);

  const imageUrlPortrait = url?.includes('scene_preview.jpeg')
    ? url?.replace('scene_preview.jpeg', 'scene_preview_portrait.jpeg')
    : url?.includes('scene_thumbnail.jpeg')
    ? url?.replace('scene_thumbnail.jpeg', 'scene_thumbnail_portrait.jpeg')
    : portraitUrl || url;

  const imageUrl = (portrait && canLoadPortrait ? imageUrlPortrait : url)
    ?.replace(/assets\.inspify\.io/g, 'assets.inspify.com')
    ?.replace(/videos\.inspify\.io/g, 'videos.inspify.com');

  const isVideoUrl = isVideo(url);

  return (
    <div
      className={`media-container ${imageLoaded} light-border ${
        disabled ? 'disabled' : ''
      } ${portrait ? 'portrait' : ''}`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick?.();
      }}
    >
      {url && (
        <>
          {isVideoUrl ? (
            <>
              <VideoPlayerInline
                url={url || ''}
                controls={false}
                onDuration={showDuration ? setDuration : undefined}
                controlsButton={false}
                canPlay={videoCanPlay}
              />
              {duration && (
                <div className="info">{convertSecondsToDuration(duration)}</div>
              )}
            </>
          ) : (
            <>
              <ImageWithWebp
                src={imageUrl}
                className="webp-image"
                onLoad={() => setImageLoaded('loaded')}
                onError={() => {
                  imageLoaded !== 'error' && setImageLoaded('error');
                  if (portrait) {
                    setCanLoadPortrait(false);
                  }
                }}
              />
              {thumbnailInfo && <div className="info">{thumbnailInfo}</div>}
            </>
          )}
        </>
      )}
      {type === FileType.VIDEO && !selected && <IconPlay />}

      {selected && (
        <div className="selected-icon">
          <MdCheckCircle className="check-circle" size={20} />
        </div>
      )}
      {overlay}

      <style jsx>{`
        .media-container {
          border-radius: 3px;
          overflow: hidden;
          width: 100%;
          position: relative;
          height: 0;
          padding-top: 56.3%;
          background: ${backgroundColor ||
          `${isVideo(url) ? '#000' : '#efefef'} ${
            url
              ? `url('/asset/loading-square.gif') center center no-repeat`
              : ''
          }`};
          background-size: 40px auto;
          cursor: ${onClick ? 'pointer' : 'default'};
        }
        .portrait.media-container {
          height: 100%;
          width: 0;
          padding-left: 56.3%;
        }
        .loaded.media-container {
          background: ${backgroundColor || ''} url('${imageUrl}') center center
            no-repeat;
          background-size: ${objectFit};
          background-position: center;
        }
        .error.media-container {
          background: ${backgroundColor};
        }
        :global(.webp-image) {
          width: 100%;
          height: auto;
          opacity: 0;
        }
        .disabled {
          opacity: 0.5;
          pointer-events: none;
        }
        .info {
          position: absolute;
          right: 3px;
          bottom: 3px;
          background: rgba(0, 0, 0, 0.8);
          font-size: 11px;
          border-radius: 10px;
          padding: 1px 4px;
          color: #fff;
        }
        .selected-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          background: rgba(255, 255, 255, 0.7);
          inset: 0;
        }
      `}</style>
    </div>
  );
};

export default SBThumbnailCover;
