import React from "react";
import { getElementPosition } from "../../../../utils/window";

export const SBAdditionalComponentWrapper = ({
    onFocus,
    parentId,
    children
  }: {
    onFocus: () => void;
    parentId: string;
    children: React.ReactNode;
  }) => {
    const ref = React.useRef<HTMLDivElement>(null);
  
    React.useEffect(() => {
      const parentPosition = getElementPosition(
        document.getElementById(parentId)
      );
      const handleParentScroll = () => {
        if (!ref?.current) return;
        const myPosition = getElementPosition(ref.current);
        if (parentPosition.left - myPosition.left === 0) {
          onFocus?.();
        }
      };
      if (parentId) {
        const element = document.getElementById(parentId);
        if (element) {
          element.addEventListener('scroll', handleParentScroll);
        }
      }
      return () => {
        if (parentId) {
          const element = document.getElementById(parentId);
          if (element) {
            element.removeEventListener('scroll', handleParentScroll);
          }
        }
      };
    }, [ref]);
  
    return (
      <div className="content" ref={ref}>
        {children}
      </div>
    );
  };

  export default SBAdditionalComponentWrapper