import React from 'react';

const ArrowIcon = ({ type = 'next' }: { type?: 'next' | 'prev' }) => (
  <div className="arrow-icon">
    <img className="arrow desktop" src="/asset/sb-arrow.png" alt="arrow" />
    <img
      className="arrow mobile"
      src="/asset/sb-arrow-mobile.png"
      alt="arrow"
    />
    <style jsx>{`
      .arrow-icon {
        width: 100%;
        aspect-ratio: 1;
        transform: scaleX(${type === 'next' ? 1 : -1});
        text-align: center;
      }
      .arrow.mobile {
        display: none;
      }
      .arrow {
        height: 100%;
        width: auto;
      }
      @media (max-width: 768px) {
        .arrow-icon .arrow.mobile {
          display: inline-block;
        }
        .arrow-icon .arrow.desktop {
          display: none;
        }
      }
      .arrow {
        animation: arrow-animation 2s ease-in-out infinite;
      }

      @keyframes arrow-animation {
        0%,
        10% {
          opacity: 0;
          transform: translateX(0);
        }
        30% {
          opacity: 1;
          transform: translateX(0);
        }
        70% {
          opacity: 1;
        }
        100% {
          opacity: 0;
          transform: translateX(90%);
        }
      }
    `}</style>
  </div>
);

const ArrowSlider = ({
  onNext,
  onPrev,
  disableNext,
  disablePrev,
  forceSmall
}: {
  onNext: () => void;
  onPrev: () => void;
  disableNext?: boolean;
  disablePrev?: boolean;
  forceSmall?: boolean;
}) => {
  return (
    <>
      <div
        className={`arrow prev ${forceSmall ? 'forceSmall' : ''} ${
          disablePrev ? 'disabled' : ''
        }`}
        onClick={onPrev}
      >
        <ArrowIcon type="prev" />
      </div>

      <div
        className={`arrow next ${forceSmall ? 'forceSmall' : ''} ${
          disableNext ? 'disabled' : ''
        }`}
        onClick={onNext}
      >
        <ArrowIcon type="next" />
      </div>

      <style jsx>{`
        .arrow {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          z-index: 40;
          cursor: pointer;
          width: 100px;
          transition: opacity 0.3s linear;
        }
        .disabled {
          pointer-events: none;
          visibility: hidden;
        }
        .arrow:hover,
        .arrow.hide:hover {
          opacity: 1;
        }
        .next {
          right: 0;
        }
        .prev {
          left: 0;
          text-align: right;
        }

        .forceSmall.arrow {
          width: 50px;
        }
        .forceSmall.next {
          right: 10px;
        }
        .forceSmall.prev {
          left: 10px;
        }

        @media (max-width: 768px) {
          .arrow {
            width: 50px;
          }
        }
        @media (max-height: 600px) {
          .arrow {
            width: 50px;
          }
          .next {
            right: 20px;
          }
          .prev {
            left: 20px;
          }
        }
        @media (orientation: portrait) {
          .next {
            right: 10px;
          }
          .prev {
            left: 10px;
          }
        }
      `}</style>
    </>
  );
};

export default ArrowSlider;
