import { assetBaseUrl } from '../../../config';

export const fonts = [
  {
    fontFamily: 'Montserrat Thin',
    url: `${assetBaseUrl}/fonts/Montserrat/Montserrat-Thin.ttf`
  },
  {
    fontFamily: 'Montserrat Extra Light',
    url: `${assetBaseUrl}/fonts/Montserrat/Montserrat-ExtraLight.ttf`
  },
  {
    fontFamily: 'Montserrat Light',
    url: `${assetBaseUrl}/fonts/Montserrat/Montserrat-Light.ttf`
  },

  {
    fontFamily: 'Montserrat Regular',
    url: `${assetBaseUrl}/fonts/Montserrat/Montserrat-Regular.ttf`
  },
  {
    fontFamily: 'Montserrat Medium',
    url: `${assetBaseUrl}/fonts/Montserrat/Montserrat-Medium.ttf`
  },
  {
    fontFamily: 'Montserrat Semi Bold',
    url: `${assetBaseUrl}/fonts/Montserrat/Montserrat-SemiBold.ttf`
  },
  {
    fontFamily: 'Montserrat Semi Bold Italic',
    url: `${assetBaseUrl}/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf`
  },
  {
    fontFamily: 'Montserrat Bold',
    url: `${assetBaseUrl}/fonts/Montserrat/Montserrat-Bold.ttf`
  },
  {
    fontFamily: 'Montserrat Extra Bold',
    url: `${assetBaseUrl}/fonts/Montserrat/Montserrat-ExtraBold.ttf`
  },

  {
    fontFamily: 'Montserrat Black',
    url: `${assetBaseUrl}/fonts/Montserrat/Montserrat-Black.ttf`
  },

  {
    fontFamily: 'Poppins-BlackItalic',
    url: `${assetBaseUrl}/fonts/Poppins/Poppins-BlackItalic.ttf`
  },
  {
    fontFamily: 'Poppins-Bold',
    url: `${assetBaseUrl}/fonts/Poppins/Poppins-Bold.ttf`
  },
  {
    fontFamily: 'Poppins-BoldItalic',
    url: `${assetBaseUrl}/fonts/Poppins/Poppins-BoldItalic.ttf`
  },
  {
    fontFamily: 'Poppins-ExtraBold',
    url: `${assetBaseUrl}/fonts/Poppins/Poppins-ExtraBold.ttf`
  },
  {
    fontFamily: 'Poppins-ExtraBoldItalic',
    url: `${assetBaseUrl}/fonts/Poppins/Poppins-ExtraBoldItalic.ttf`
  },
  {
    fontFamily: 'Poppins-ExtraLight',
    url: `${assetBaseUrl}/fonts/Poppins/Poppins-ExtraLight.ttf`
  },
  {
    fontFamily: 'Poppins-ExtraLightItalic',
    url: `${assetBaseUrl}/fonts/Poppins/Poppins-ExtraLightItalic.ttf`
  },
  {
    fontFamily: 'Poppins-Italic',
    url: `${assetBaseUrl}/fonts/Poppins/Poppins-Italic.ttf`
  },
  {
    fontFamily: 'Poppins-Light',
    url: `${assetBaseUrl}/fonts/Poppins/Poppins-Light.ttf`
  },
  {
    fontFamily: 'Poppins-LightItalic',
    url: `${assetBaseUrl}/fonts/Poppins/Poppins-LightItalic.ttf`
  },
  {
    fontFamily: 'Poppins-Medium',
    url: `${assetBaseUrl}/fonts/Poppins/Poppins-Medium.ttf`
  },
  {
    fontFamily: 'Poppins-MediumItalic',
    url: `${assetBaseUrl}/fonts/Poppins/Poppins-MediumItalic.ttf`
  },
  {
    fontFamily: 'Poppins-Regular',
    url: `${assetBaseUrl}/fonts/Poppins/Poppins-Regular.ttf`
  },
  {
    fontFamily: 'Poppins-SemiBold',
    url: `${assetBaseUrl}/fonts/Poppins/Poppins-SemiBold.ttf`
  },
  {
    fontFamily: 'Poppins-SemiBoldItalic',
    url: `${assetBaseUrl}/fonts/Poppins/Poppins-SemiBoldItalic.ttf`
  },
  {
    fontFamily: 'Poppins-Thin',
    url: `${assetBaseUrl}/fonts/Poppins/Poppins-Thin.ttf`
  },
  {
    fontFamily: 'Poppins-ThinItalic',
    url: `${assetBaseUrl}/fonts/Poppins/Poppins-ThinItalic.ttf`
  },
  {
    fontFamily: 'Poppins-Black',
    url: `${assetBaseUrl}/fonts/Poppins/Poppins-Black.ttf`
  }
];
