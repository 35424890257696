import { differenceInMilliseconds } from 'date-fns';
import React from 'react';
import { logEyeballTime, logEyeballTimeOnExit } from '../analytics';
import { EyeballLoggerPayload, EyeballTimeView } from '../interfaces';

const EyeballTimeLogger = (props: {
  children: React.ReactNode;
  tracker: EyeballLoggerPayload<EyeballTimeView>;
}) => {
  const startTime = React.useRef(new Date());
  const [visibility, setVisibility] = React.useState<'visible' | 'hidden'>();

  const logDuration = () => {
    const duration = differenceInMilliseconds(new Date(), startTime.current);
    logEyeballTime(duration, props.tracker);
  };

  const logDurationOnExit = () => {
    const duration = differenceInMilliseconds(new Date(), startTime.current);
    logEyeballTimeOnExit(duration, props.tracker);
  };

  const onvisibilitychange = () => setVisibility(document.visibilityState);

  const onPageHide = () => {
    // console.log('page hide', e);
    // if (!e.persisted) {
    logDurationOnExit();
    // }
  };

  React.useEffect(() => {
    document.addEventListener('visibilitychange', onvisibilitychange);
    // window.addEventListener('pagehide', onPageHide);
    return () => {
      // Only log duration on unmount
      logDuration();
      // window.removeEventListener('pagehide', onPageHide);
      document.removeEventListener('visibilitychange', onvisibilitychange);
    };
  }, []);

  React.useEffect(() => {
    if (visibility === 'visible') {
      // Reset start time when the app gains focus
      startTime.current = new Date();
    } else {
      onPageHide();
    }
    // No need to log when visibility changes to 'hidden'
  }, [visibility]);

  return <>{props.children}</>;
};
export default EyeballTimeLogger;
