import React from 'react';
import { SceneLayerSettings } from '../../../../interfaces';
import { generatePageDimension } from '../../../../mappers/polotno';

export const SBLayerGif = ({
  setting,
  onError,
  portrait,
  layerType
}: {
  setting: SceneLayerSettings;
  onError: (details: any) => void;
  portrait: boolean;
  layerType: 'mobile' | 'desktop';
}) => {
  const { url, width, height, x, y, opacity, rotation, flipX, flipY } = setting;
  const [pageWidth, pageHeight] = React.useMemo(
    () => generatePageDimension(portrait && layerType === 'mobile'),
    [url, portrait, layerType]
  );

  const relativeX = (x / pageWidth) * 100;
  const relativeY = (y / pageHeight) * 100;
  const relativeWidth = (width / pageWidth) * 100;
  const relativeHeight = (height / pageHeight) * 100;

  return (
    <>
      <img
        src={url}
        style={{
          left: `${relativeX}%`,
          top: `${relativeY}%`,
          width: `${relativeWidth}%`,
          height: `${relativeHeight}%`,
          opacity,
          transform: `rotate(${rotation}deg)${flipY ? ' scaleY(-1)' : ''}${
            flipX ? ` scaleX(-1)` : ''
          }`,
          transformOrigin: rotation && !flipX ? 'left top' : 'center center'
        }}
        width={width}
        height={height}
        onError={onError}
      />
      <style jsx>{`
        img {
          position: absolute;
          pointer-events: none;
        }
      `}</style>
    </>
  );
};

export default SBLayerGif;
