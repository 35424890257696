import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePageVisibility } from '../../../../components/hooks/ux';
import {
  IMainState,
  IStorybookPage,
  IStorybookSettings,
  MeetingRole
} from '../../../../interfaces';
import {
  actionOpenVBPopup,
  actionPopupRedirect
} from '../../../../redux/actions';
import { IMAGE_ERROR, VIDEO_ERROR } from '../../../../utils/constants';
import { useInspifyWebAppUrlIfNeeded } from '../../../../utils/urlChecker';
import VideoPlayerInline from '../Common/VideoPlayerInline';
import SBLayerActions from './SBLayerActions';
import SBLayerGif from './SBLayerGif';
import SBLayerImage from './SBLayerImage';
import SBLayerVideo from './SBLayerVideo';
import SBLayerWrapper from './SBLayerWrapper';
import { useSspState } from '../../../../components/hooks/common';
import {
  getParentWindowMeetingRole,
  isParentWindowInMeeting
} from '../../../../utils/window';
import SBLayerProduct from './SBLayerProduct';
import { getProductsByIds } from '../../../../clientSideServices/products';
import { TLanguage } from '../../../../mappers/polotno';
import { fonts } from '../../Polotno/customFonts';
import ImageWithWebp from '../../Common/ImageWithWebp';

export const SBImagePage = ({
  page,
  setting,
  handleError,
  portrait
}: {
  setting: IStorybookSettings;
  page: IStorybookPage;
  handleError?: (message: string, payload: any) => void;
  portrait?: boolean;
}) => {
  const url = page.url;
  const [hasPortrait, setHasPortrait] = React.useState(true);
  const isInSsp = useSspState();
  const portraitUrl =
    setting.portraitUrl || url.replace('scene.jpeg', 'scene_portrait.jpeg');

  const handleImageError = (event) => {
    handleError?.(IMAGE_ERROR, {
      imageUrl: event.target.src,
      pageId: page?.id
    });
    if (portrait) {
      setHasPortrait(false);
    }
  };

  const imageUrl = hasPortrait && portrait ? portraitUrl : url;
  const loadingMethod = !isInSsp ? 'lazy' : 'eager';
  return (
    <>
      <ImageWithWebp
        className="webp-image-microsite"
        src={imageUrl}
        onError={handleImageError}
        loading={loadingMethod}
      />
      <style jsx>{`
        :global(.webp-image-microsite) {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
        @media (orientation: portrait) {
          :global(.webp-image-microsite) {
            object-position: center;
          }
        }
      `}</style>
    </>
  );
};

export const SBVideoPage = ({
  page,
  hideVideo,
  setting,
  canPlay,
  handleError,
  controls,
  controlsButton,
  portrait,
  forceMute
}: {
  page: IStorybookPage;
  hideVideo?: boolean;
  setting: IStorybookSettings;
  canPlay?: boolean;
  handleError?: (message: string, payload: any) => void;
  controls?: boolean;
  controlsButton?: boolean;
  portrait?: boolean;
  forceMute?: boolean;
}) => {
  const url = portrait ? setting?.portraitUrl || page.url : page.url;
  const { autoplay, videoLoop } = setting;

  const handleVideoError = (details) => {
    handleError?.(VIDEO_ERROR, {
      details
    });
  };

  return (
    <VideoPlayerInline
      url={url}
      hideVideo={hideVideo}
      playing={autoplay}
      loop={videoLoop}
      audio={!videoLoop}
      canPlay={canPlay}
      controls={controls}
      controlsButton={controlsButton}
      onError={handleVideoError}
      autoMute={forceMute && autoplay}
      largePlayButton={false}
      smallPlayButton={true}
    />
  );
};

export const SBLayerPage = ({
  page,
  setting,
  canPlay,
  hideVideo,
  handleError = () => null,
  portrait,
  controls = false,
  id = 'true',
  activateLanguage
}: {
  page: IStorybookPage;
  setting: IStorybookSettings;
  canPlay: boolean;
  hideVideo?: boolean;
  handleError?: (message: string, payload: any) => void;
  portrait: boolean;
  controls?: boolean;
  id?: string;
  activateLanguage?: TLanguage;
}) => {
  const dispatch = useDispatch();
  const meeting = useSelector(
    (state: IMainState) => state?.clientState?.meeting
  );
  const { layer } = setting;
  const language = activateLanguage || 'en';
  const layerMobile =
    layer?.[`mobile${language === 'en' ? '' : `-${language}`}`] ||
    layer?.mobile;
  const layerDesktop =
    layer?.[`desktop${language === 'en' ? '' : `-${language}`}`] ||
    layer?.desktop;
  const layers = portrait ? layerMobile || layerDesktop : layerDesktop;
  const [products, setProducts] = React.useState([]);
  const layerRef = React.useRef<HTMLDivElement>(null);

  const pageIsVisible = usePageVisibility();

  const [parentDimension, setParentDimension] = React.useState({
    width: 0,
    height: 0
  });

  const handleImageError = (event) => {
    handleError?.(IMAGE_ERROR, {
      imageUrl: event.target.src,
      pageId: page?.id
    });
  };

  const handleVideoError = (details) => {
    handleError?.(VIDEO_ERROR, {
      details
    });
  };

  const handleOpenPopup = (url: string) => {
    const isInMeeting = meeting?.meetingId;
    const isPresenter = meeting?.isPresenter;
    if (isInMeeting && !isPresenter) return;
    const isParentWindowPresenter =
      getParentWindowMeetingRole() === MeetingRole.ADVISOR;
    const isEmbeddedInMeeting = isParentWindowInMeeting();
    //if it's a popup
    if (isEmbeddedInMeeting && !isInMeeting && isParentWindowPresenter) {
      return dispatch(actionPopupRedirect(useInspifyWebAppUrlIfNeeded(url)));
    }
    //default behavior
    dispatch(actionOpenVBPopup(useInspifyWebAppUrlIfNeeded(url)));
  };

  React.useEffect(() => {
    if (!layerRef?.current) return;

    const myObserver = new ResizeObserver((entries) => {
      const { width, height } = entries[0]?.contentRect || {
        width: 0,
        height: 0
      };
      setParentDimension({ width, height });
    });

    myObserver.observe(layerRef?.current);
    return () => {
      if (myObserver) myObserver.disconnect();
    };
  }, [layerRef]);

  React.useEffect(() => {
    const productIds = layers
      ?.filter((lay) => lay.type === 'product')
      ?.map((lay) => lay.product.id)
      ?.reduce((acc, cur) => {
        if (acc.indexOf(cur) === -1) {
          acc.push(cur);
        }
        return acc;
      }, []);

    if (productIds?.length > 0) {
      getProductsByIds(productIds)
        .then((res) => {
          setProducts(res.data);
        })
        .catch((e) => {
          console.log('productIds error', e);
        });
    }
  }, []);

  const getProductById = (id) => {
    return products.find((product) => product.id === id);
  };
  const layerVideoImage = layers?.filter((lay) => lay?.type !== 'action');
  const layerActions = layers?.filter((lay) => lay?.type === 'action');
  return (
    <div className="layer-page" ref={layerRef}>
      <SBLayerWrapper
        portrait={layerMobile ? portrait : false}
        parentDimension={parentDimension}
      >
        {layerVideoImage?.map((lay, index) =>
          lay?.type === 'video' ? (
            <SBLayerVideo
              key={lay.url + index}
              canPlay={canPlay}
              onError={handleVideoError}
              setting={lay}
              portrait={layerMobile ? portrait : false}
              controls={!!controls}
              pageIsVisible={pageIsVisible}
              hideVideo={hideVideo}
            />
          ) : lay?.type === 'gif' ? (
            <SBLayerGif
              key={lay.url + index}
              onError={handleImageError}
              setting={lay}
              portrait={portrait}
              layerType={
                portrait ? (layerMobile ? 'mobile' : 'desktop') : 'desktop'
              }
            />
          ) : lay.type === 'product' ? (
            <SBLayerProduct
              key={lay.product.defaultValue + index}
              setting={lay}
              onError={handleImageError}
              portrait={layerMobile ? portrait : false}
              product={getProductById(lay.product.id)}
              fonts={setting?.customFont || fonts}
            />
          ) : (
            <SBLayerImage
              key={lay.url + index}
              onError={handleImageError}
              url={lay.url
                ?.replace(/assets\.inspify\.io/g, 'assets.inspify.com')
                ?.replace(/videos\.inspify\.io/g, 'videos.inspify.com')}
              portrait={portrait}
            />
          )
        )}
        {layerActions?.length > 0 && (
          <SBLayerActions
            layers={layerActions}
            portrait={layerMobile ? portrait : false}
            onOpenPopup={handleOpenPopup}
            id={id}
            pageId={page?.id}
          />
        )}
      </SBLayerWrapper>
      <style jsx>{`
        .layer-page {
          width: 100%;
          height: 100%;
          position: relative;
        }
      `}</style>
    </div>
  );
};
