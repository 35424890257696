import { assetBaseUrl } from '../../../config';

const CHLoadingIcon = ({
  size = 50,
  dark
}: {
  size?: number;
  dark?: boolean;
}) => {
  return (
    <img
      src={`${assetBaseUrl}/chopard/chsps/loading_${
        dark ? 'black' : 'white'
      }.gif`}
      style={{ width: size + 'px', height: 'auto' }}
    />
  );
};

export default CHLoadingIcon;
