import { delay, throttle } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { logEvent, logKeyPress } from '../../../../analytics';
import EyeballTimeLogger from '../../../../components/EyeballTimeLogger';
import {
  IMainState,
  IStorybook,
  MeetingRole,
  PageContentView
} from '../../../../interfaces';
import { DID_SELECT_PAGE, DID_VIEW_CONTENT } from '../../../../utils/constants';
import { isImage } from '../../../../utils/file';
import {
  getParentWindowMeetingRole,
  getViewportSize,
  scrollElementIntoViewById
} from '../../../../utils/window';
import {
  getLanguaImageFromJSON,
  getSBPageSetting
} from '../../../utils/storybook';

import SBAdditionalComponentWrapper from './SBAdditionalComponentWrapper';
import SBMicrositeNavigation from './SBMicrositeNavigation';
import SBMicrositePage from './SBMicrositePage';
import {
  actionUpdateStorybookIndex,
  actionUpdateViewportInfo
} from '../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { TLanguage } from '../../../../mappers/polotno';
import {
  isUserOnMobile,
  isUserOnMobileOnly
} from '../../../../utils/deviceDetector';
import { getViewportType } from '../../../../utils/meeting';

export interface SBMicrositeAdditionalComponent {
  component: React.ReactNode;
  thumbnail: string;
}
const SBMicrosite = ({
  storybook,
  additionalComponent,
  portrait,
  muteFirstPage,
  initialPage = 0,
  onPageChange,
  activateLanguage
}: {
  storybook: IStorybook;
  additionalComponent?: SBMicrositeAdditionalComponent;
  portrait?: boolean;
  muteFirstPage?: boolean;
  initialPage?: number;
  onPageChange?: (page: number) => void;
  activateLanguage?: TLanguage;
}) => {
  const pages = storybook.pages;
  const pagesNumbers = Array.from(Array(pages.length).keys());

  const [jumpToPage, setJumpToPage] = React.useState(initialPage);
  const [activePage, setActivePage] = React.useState(initialPage);
  const [hideVideoPages, setHideVideoPages] = React.useState<number[]>([]);
  const selectedPage = pages[activePage];
  const contentLength = additionalComponent ? pages.length + 1 : pages.length;
  const [orientation, setOrientation] = React.useState(false);
  // To detect whether user clicked the button navigation or not
  const isClickedButtonRef = useRef<boolean>(false); 
  const onOrientationChange = () => {
    setOrientation(true);
    setTimeout(() => setOrientation(false), 500);
  };
  const dispatch = useDispatch();
  const storybookViewerIndex = useSelector(
    (state: IMainState) => state.clientState?.storybookViewerIndex
  );

  const handleLogEvent = (index: number) => {
    const newPage = pages[index];
    const language = 'en';
    const url = getLanguaImageFromJSON(newPage?.url, language);
    logEvent(DID_SELECT_PAGE, DID_SELECT_PAGE, {
      id: storybook.id,
      title: storybook.title,
      contentUrl: url,
      contentTitle:
        index === pages.length
          ? 'End Storybook Additional Content'
          : newPage?.title,
      contentSubtitle: newPage?.subtitle,
      pageNumber: index + 1,
      storybookId: storybook?.id,
      uuid: newPage?.id,
      organisationId: storybook.brand
    });
  }

  useEffect(() => {
    setHideVideoPages(
      pagesNumbers.filter(
        (page) => page < activePage - 1 || page > activePage + 1
      )
    );
  }, [activePage]);

  const updateIndex = (
    index: number,
    isLogEventDidSelectPage = true,
    isClickedButton = false
  ) => {
    setActivePage(index);
    setJumpToPage(index);
    onPageChange?.(index);
    dispatch(actionUpdateStorybookIndex(index));
    if (isLogEventDidSelectPage && !isClickedButton) {
      handleLogEvent(index);
    }
    if (isClickedButton) {
      isClickedButtonRef.current = false;
    }
  };

  const handlePrev = () => {
    const index = activePage > 0 ? activePage - 1 : 0;
    updateIndex(index);
  };

  const handleNext = () => {
    const index =
      activePage < contentLength - 1 ? activePage + 1 : contentLength - 1;
    updateIndex(index);
  };

  const handleKeyDown = React.useCallback(
    (e) => {
      e.preventDefault();
      e = e || window.event;
      if (e.keyCode == '37') {
        handlePrev();
        logKeyPress('37');
      }
      if (e.keyCode == '39') {
        handleNext();
        logKeyPress('39');
      }
    },
    [activePage]
  );

  const nextImage = pages.find(
    (page, i) => i > activePage && isImage(page.url)
  )?.url;

  React.useEffect(() => {
    if (contentLength <= 1) return;
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      if (contentLength <= 1) return;
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  React.useEffect(() => {
    scrollElementIntoViewById(`page-${jumpToPage}`, true);
  }, [jumpToPage]);

  React.useEffect(() => {
    onOrientationChange();
    const activeElement = document.querySelector(`#page-${activePage}`);
    if (activeElement) activeElement.scrollIntoView();
  }, [portrait]);

  React.useEffect(() => {
    if (initialPage && initialPage !== activePage)
      delay(() => updateIndex(initialPage, false), 1);
  }, [initialPage, portrait]);

  React.useEffect(() => {
    if (storybook) {
      logEvent(DID_VIEW_CONTENT, DID_VIEW_CONTENT, {
        contentType: PageContentView.STORYBOOK_VIEWER,
        id: storybook.id,
        title: storybook.title,
        viewerType: 'microsite',
        organisationId: storybook.brand,
        uuid: storybook.id
      });
      if (selectedPage) {
        logEvent(DID_VIEW_CONTENT, DID_VIEW_CONTENT, {
          contentType: PageContentView.STORYBOOK_VIEWER,
          id: storybook.id,
          title: storybook.title,
          viewerType: 'microsite',
          organisationId: storybook.brand,
          uuid: selectedPage.id,
          pageId: selectedPage.id
        });
      }
    }
  }, []);

  React.useEffect(() => {
    if (
      storybookViewerIndex !== undefined &&
      storybookViewerIndex !== activePage &&
      getParentWindowMeetingRole() !== MeetingRole.ADVISOR
    ) {
      updateIndex(storybookViewerIndex, false);
    }
  }, [storybookViewerIndex]);

  React.useEffect(() => {
    const onViewPortResize = throttle(() => {
      const { vh, vw } = getViewportSize();
      const viewportInfo = {
        width: vw,
        height: vh,
        onPhone: isUserOnMobileOnly(),
        onMobile: isUserOnMobile(),
        type: getViewportType({ width: vw, height: vh })
      };
      dispatch(actionUpdateViewportInfo(viewportInfo));
    }, 200);
    onViewPortResize();
    window.addEventListener('resize', onViewPortResize);
    return () => {
      window.removeEventListener('resize', onViewPortResize);
    };
  }, []);

  const handleError = (message: string, payload: any) => {
    logEvent(message, message, {
      storybookId: storybook.id,
      uuid: storybook.id,
      organisationId: storybook.brand,
      viewerType: 'microsite',
      contentType: PageContentView.STORYBOOK_VIEWER,
      ...payload
    });
  };

  return (
    <EyeballTimeLogger
      tracker={{
        view: PageContentView.STORYBOOK_VIEWER,
        id: storybook.id,
        title: storybook.title,
        organisationId: storybook.brand,
        uuid: storybook.id
      }}
    >
      <div className="SBPreviewDefault d-flex flex-column h-100">
        <EyeballTimeLogger
          key={selectedPage?.url}
          tracker={{
            view: PageContentView.CONTENT_PAGE,
            id: storybook.id,
            title: storybook.title,
            contentUrl: getLanguaImageFromJSON(selectedPage?.url, 'en'),
            contentTitle: selectedPage?.title,
            contentSubtitle: selectedPage?.subtitle,
            pageNumber: activePage + 1,
            pageId: selectedPage?.id,
            uuid: storybook.id,
            organisationId: storybook.brand
          }}
        >
          {null}
        </EyeballTimeLogger>
        <div className="pages-container" id="pageContainer">
          {pages.map(
            (page, i) =>
              ((orientation && i <= activePage) || !orientation) && (
                <div className="page" id={`page-${i}`} key={page.url + i}>
                  <SBMicrositePage
                    page={page}
                    activateLanguage={activateLanguage}
                    hideVideo={hideVideoPages.includes(i)}
                    setting={getSBPageSetting(
                      page?.settings || '',
                      storybook?.settings || ''
                    )}
                    active={activePage === i}
                    onFocus={() => {
                      updateIndex(i, true, isClickedButtonRef.current);
                      const selectedPage = pages[i];
                      logEvent(DID_VIEW_CONTENT, DID_VIEW_CONTENT, {
                        contentType: PageContentView.STORYBOOK_VIEWER,
                        id: storybook.id,
                        title: storybook.title,
                        viewerType: 'microsite',
                        organisationId: storybook.brand,
                        uuid: selectedPage.id,
                        pageId: selectedPage.id
                      });
                    }}
                    parentId="pageContainer"
                    handleError={handleError}
                    portrait={portrait}
                    forceMute={muteFirstPage && i === 0}
                    storybookId={storybook.id}
                  />
                </div>
              )
          )}
          {additionalComponent &&
          (!orientation || activePage === contentLength - 1) ? (
            <div className="page" id={`page-${contentLength - 1}`}>
              <SBAdditionalComponentWrapper
                parentId="pageContainer"
                onFocus={() => {
                  updateIndex(contentLength - 1, false);
                }}
              >
                {activePage === contentLength - 1 &&
                  additionalComponent.component}
              </SBAdditionalComponentWrapper>
            </div>
          ) : null}
        </div>

        {contentLength > 1 && (
          <SBMicrositeNavigation
            storybook={storybook}
            activePage={activePage}
            onNext={handleNext}
            onPrev={handlePrev}
            onClickPage={(index) => {
              updateIndex(index, false, isClickedButtonRef.current);
              const newPage = pages[index];
              const language = 'en';
              const url = getLanguaImageFromJSON(newPage?.url, language);
              logEvent(DID_SELECT_PAGE, DID_SELECT_PAGE, {
                id: storybook.id,
                title: storybook.title,
                contentUrl: url,
                contentTitle:
                  index === pages.length
                    ? 'End Storybook Additional Content'
                    : newPage?.title,
                contentSubtitle: newPage?.subtitle,
                pageNumber: index + 1,
                storybookId: storybook?.id,
                uuid: newPage?.id,
                organisationId: storybook.brand
              });
            }}
            portrait={portrait}
            totalExtraPages={additionalComponent ? 1 : 0}
            onToggle={() => {
              if (hideVideoPages.length !== 0) setHideVideoPages([]);
            }}
            isClickedButtonRef={isClickedButtonRef}
          />
        )}
        {nextImage && (
          <img loading="lazy" className="preload" src={nextImage} />
        )}
        <style jsx>{`
          .SBPreviewDefault {
            overflow: hidden;
            width: 100%;
          }
          .pages-container {
            display: flex;
            overflow-y: hidden;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;
            flex-flow: row nowrap;
            width: 100%;
            height: 100%;
            -ms-overflow-style: none !important;
            scrollbar-width: none !important;
          }
          .page {
            width: 100%;
            height: 100%;
            scroll-snap-align: center;
            flex: none;
          }
          .pages-container::-webkit-scrollbar {
            display: none !important;
          }
          .preload {
            position: absolute;
            left: 0;
            top: 0;
            width: 1px;
            height: 1px;
          }
        `}</style>
      </div>
    </EyeballTimeLogger>
  );
};

export default SBMicrosite;
