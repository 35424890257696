import React from 'react';
import { useSelector } from 'react-redux';
import { initGA } from '../../analytics';
import { IMainState } from '../../interfaces';
import { storesWithCookieBanner } from '../../utils/cookie';
import { isUserOnMobile } from '../../utils/deviceDetector';
import { getGaTrackingIdByStore } from '../../utils/stores';
import { isEmbeddedInStreamingStudio } from '../../utils/streamingstudio';
import { getViewportSize } from '../../utils/window';

const GA = ({
  storeId,
  children
}: {
  storeId?: string;
  children: React.ReactNode;
}) => {
  const [isOnMobile, setIsOnMobile] = React.useState(false);
  const [isEmbeddedInSsp, setIsEmbeddedInSsp] = React.useState(false);

  const agreedToCookie = useSelector(
    (state: IMainState) => state.clientState.agreedToCookie
  );
  const initAnalytics = () => {
    initGA(getGaTrackingIdByStore(storeId));
  };
  React.useEffect(() => {
    if (!storesWithCookieBanner.includes(storeId)) {
      initAnalytics();
    }
    setIsOnMobile(isUserOnMobile());
    setIsEmbeddedInSsp(isEmbeddedInStreamingStudio() && (getViewportSize().vw > 960));
  }, []);

  React.useEffect(() => {
    if (agreedToCookie) {
      initAnalytics();
    }
  }, [agreedToCookie]);

  return <div className={`${isOnMobile ? 'vbtouch' : ''} ${
    isEmbeddedInSsp ? 'vbssp' : ''}`}>{children}</div>;
};

export default GA;
